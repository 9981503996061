import {
  fetchGetBlogPosts,
  fetchCreateBlogPost,
  fetchUpdateBlogPost,
} from "./queries.js";

const getBlogPosts = async () => {
  try {
    const result = await fetchGetBlogPosts();

    if (result.message) {
      return result;
    }

    return { success: true, posts: result.posts };
  } catch (error) {
    return { success: false, message: "Get blog posts failed" };
  }
};

const createBlogPost = async (data) => {
  try {
    const result = await fetchCreateBlogPost(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Create blog post failed" };
  }
};

const updateBlogPost = async (id, data) => {
  try {
    const result = await fetchUpdateBlogPost(id, data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Update blog post failed" };
  }
};

export { getBlogPosts, createBlogPost, updateBlogPost };
