import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import Loader from "../Loader";
import ConnectBtn from "../ConnectBtn";
import SimpleConnect from "../Settings/TabContent/Integrations/SimpleConnect";
import ConfirmPopup from "../ConfirmPopup";
import SuggestionPopup from "../SuggestionPopup";

import { getObjectFromLocalStorage } from "../../api/localStorage";
import {
  checkEmailHealth,
  createIntegration,
} from "../../services/integrations";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { displayIntegrationDate } from "./utils";
import { BtnGroup, ConnectedProviderBlock, OutlineBtn } from "./styles";
import DoItForMeButton from "./DoItForMeButton";
import ConfirmKeyGen from "./ConfirmKeyGen";
import WizardStepControls from "./WizardStepControls";
import { isCompanyAdvIntegrationsSettings } from "../../utils/utils";

const oAuthMethod = "oauth";

const emailProviders = {
  office365: {
    messageTypeForOpenKeyGenWizard: "automate-o365-key-gen-open-wizard",
    label: "Office365",
    iconSrc: "office365-icon.png",
    tabIconSrc: "office365-icon.png",
    hoverIconSrc: "office365-icon.png",
    value: "office365",
    description:
      "Use QuickConnect to connect to Office365 via OAuth.  If you're on a paid plan, you can also connect via Enterprise mode",
  },
  gsuite: {
    messageTypeForOpenKeyGenWizard: "open-automate-gsuite-key-gen-wizard",
    label: "GSuite",
    iconSrc: "gsuite-icon.png",
    tabIconSrc: "gsuite-icon.png",
    hoverIconSrc: "gsuite-icon.png",
    value: "gsuite",
    description:
      "Use QuickConnect to connect to GSuite via OAuth.  If you're on a paid plan, you can also connect via Enterprise mode",
  },
};

const methods = {
  oAuth: { value: "oauth", title: "Quick Connect" },
  enterprise: { value: "enterprise", title: "Enterprise Connect" },
};

const SetEmailProvider = ({
  onConnected,
  isWizard,
  goToTab,
  enterpriseDataToSet,
  connectionFields,
  onReloadIntegrations,
  onDisconnect,
  stepConfig,
}) => {
  const companyInfo = getObjectFromLocalStorage("companyInfo");
  const isAllowedEnterpriseConnect =
    isCompanyAdvIntegrationsSettings(companyInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [connectionInfo, setConnectionInfo] = useState(connectionFields);

  const [activeProvider, setActiveProvider] = useState(
    enterpriseDataToSet?.type || null
  );
  const [connectedProvider, setConnectedProvider] = useState(
    !enterpriseDataToSet && connectionFields && connectionFields.type
      ? connectionFields.type
      : null
  );
  const [activeMethod, setActiveMethod] = useState(
    enterpriseDataToSet ? methods.enterprise : null
  );

  const [secretKey, setSecretKey] = useState(enterpriseDataToSet?.secret || "");
  const [activeDirectoryId, setActiveDirectoryId] = useState(
    enterpriseDataToSet?.activeDirectoryId || ""
  );
  const [appRegistrationId, setAppRegistrationId] = useState(
    enterpriseDataToSet?.appRegistrationId || ""
  );
  const [clientEmail, setClientEmail] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const [isReconnecting, setIsReconnecting] = useState(!!enterpriseDataToSet);
  const [isConfirmDisconnectPopupOpen, setIsConfirmDisconnectPopupOpen] =
    useState(false);

  const [isConfirmKeyGen, setIsConfirmKeyGen] = useState(false);

  const [isOpenedErrorPopup, setIsOpenedErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [emailHealth, setEmailHealth] = useState(null);

  const isOAuthIntegration =
    connectionInfo?.method === oAuthMethod &&
    connectionInfo?.type === activeProvider;

  const handleProviderClick = (key) => {
    setIsReconnecting(true);
    setActiveProvider(key);
    setConnectedProvider(null);
    setActiveMethod(
      isAllowSimpleConnect() ? methods.oAuth : methods.enterprise
    );
  };

  const handleMethodClick = (method) => {
    setActiveMethod(method);
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  const isCustomProvider = () => {
    const { email } = getUserFromLocalStorage();
    const verifiedProviders = ["google.com", "outlook.com"];
    const provider = email.split("@")[1];
    return !verifiedProviders.includes(provider);
  };

  const isAllowSimpleConnect = () => {
    return (
      companyInfo?.company?.allowSimpleConnect &&
      (isCompanyAdmin() ||
        !connectionInfo?.type ||
        connectionInfo?.method === oAuthMethod)
    );
  };

  const handleClickByDoItForMe = async () => {
    setIsConfirmKeyGen(true);
  };

  const onCloseProviderSettings = () => {
    setActiveProvider(null);
    setConnectedProvider(
      connectionFields && connectionFields.type ? connectionFields.type : null
    );

    setSecretKey("");
    setActiveDirectoryId("");
    setAppRegistrationId("");
    setClientEmail("");
    setPrivateKey("");

    setIsReconnecting(false);
  };

  const handleEditIntegration = () => {
    setActiveProvider(connectedProvider);
    setActiveMethod(
      connectionInfo.method === methods.oAuth.value
        ? methods.oAuth
        : methods.enterprise
    );
    setConnectedProvider(null);
    setIsReconnecting(true);
  };

  const handleDisconnect = async () => {
    setIsConfirmDisconnectPopupOpen(false);
    const success = !onDisconnect || (await onDisconnect(connectedProvider));
    if (success) {
      setConnectionInfo({});
      setConnectedProvider(null);
      setActiveMethod(
        isAllowSimpleConnect() ? methods.oAuth : methods.enterprise
      );
      setIsReconnecting(true);
    }
  };

  const availableProvidersIcons = () => {
    if (!connectedProvider) return [];
    return Object.keys(emailProviders).filter(
      (key) => key !== connectedProvider
    );
  };

  const handleConnect = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "These values can be set only by company admin",
        },
      });
      return;
    }

    const connectionKeys =
      activeProvider === emailProviders.office365.value
        ? {
            activeDirectoryId,
            appRegistrationId,
            secret: secretKey,
          }
        : {
            clientEmail,
            privateKey: privateKey.replace(/\\n/g, "\n"),
          };

    const data = {
      type: "email",
      connectionKeys: {
        type: activeProvider,
        ...connectionKeys,
      },
    };

    setIsLoading(true);
    const result = await createIntegration(data);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const newData = {};
    Object.keys(data.connectionKeys).forEach((key) => {
      if (key !== "type") {
        newData[key] = data.connectionKeys[key].replace(/[^-]/g, "*");
      } else {
        newData[key] = data.connectionKeys[key];
      }
    });

    newData.expired = false;
    newData.type = activeProvider;
    newData.createdAt = new Date().toISOString();

    setConnectionInfo(newData);
    setSecretKey("");
    setActiveDirectoryId("");
    setAppRegistrationId("");
    setClientEmail("");
    setPrivateKey("");

    setIsReconnecting(false);
    setConnectedProvider(activeProvider);
    setIsLoading(false);
    onConnected("emailService");
  };

  const handleSimpleConnect = async (isSuccess, message) => {
    const setNewData = (newData) => {
      setConnectionInfo(newData);
      setSecretKey("");
      setActiveDirectoryId("");
      setAppRegistrationId("");
      setClientEmail("");
      setPrivateKey("");
      setIsReconnecting(false);
      setConnectedProvider(newData.type);
      setIsLoading(false);
      onConnected("emailService");
    };
    if (isSuccess) {
      const newData = {
        type: activeProvider,
        method: oAuthMethod,
        expired: false,
      };
      setNewData(newData);
    } else {
      setIsOpenedErrorPopup(true);
      setErrorMessage(message);
    }

    if (onReloadIntegrations) {
      const integration = await onReloadIntegrations();
      if (!isSuccess && integration?.email?.type) {
        setNewData(integration.email);
      }
    }
  };

  const simpleConnect = () => (
    <SimpleConnect
      {...(isOAuthIntegration && connectionInfo?.expired === false
        ? { label: "Reconnect OAuth" }
        : {})}
      onConnect={handleSimpleConnect}
      emailType={activeProvider}
    />
  );

  const getTabKeys = () => {
    return Object.keys(methods).filter(
      (key) => isAllowedEnterpriseConnect || key !== methods.enterprise.value
    );
  };

  const handleCheckEmailHealth = async () => {
    const { success, result } = await checkEmailHealth();

    if (!success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Unable to check email health",
        },
      });
      return;
    }

    setEmailHealth(result.emailRecords);
  };

  return (
    <Section>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <OverflowContent>
          {isConfirmDisconnectPopupOpen && (
            <ConfirmPopup
              title="Are you sure?"
              description={`You’re about to disconnect ${connectedProvider} integration. Are you sure you want to proceed?`}
              onConfirm={handleDisconnect}
              onCancel={() => setIsConfirmDisconnectPopupOpen(false)}
            />
          )}

          {emailHealth && (
            <SuggestionPopup
              title="Email domain health"
              description={`${emailHealth.dkim.description}\n\n${emailHealth.dmarc.description}\n\n${emailHealth.spf.description}`}
              onConfirm={() => {
                setEmailHealth(null);
              }}
              confirmBtnText="Got it!"
              imageSrc="/images/lamp.png"
              removeCancel
              biggerHeight
            />
          )}

          {isConfirmKeyGen && (
            <ConfirmKeyGen
              provider={activeProvider}
              titleForGenerate={`Generate the ${emailProviders[activeProvider].label} key`}
              descForGenerate="We will generate the credentials and promptly provide it to you."
              providerLabelForLogin={
                activeProvider === emailProviders.office365.value
                  ? "Azure"
                  : "GSuite"
              }
              messageTypeForOpenKeyGenWizard={
                emailProviders[activeProvider].messageTypeForOpenKeyGenWizard
              }
              onClose={() => setIsConfirmKeyGen(false)}
            />
          )}

          {isOpenedErrorPopup && (
            <SuggestionPopup
              title="Connection was unsuccessful"
              description={
                errorMessage ||
                "Unfortunately, we were unable to create an integration with this account. You may need to contact your IT team or email us support@salesstream.ai for help"
              }
              onConfirm={() => {
                setIsOpenedErrorPopup(false);
              }}
              confirmBtnText="Got it!"
              imageSrc="/images/sad-computer.png"
              removeCancel={true}
              styles={{
                titleStyle: {
                  fontColor: "#ff0000",
                },
              }}
            />
          )}

          <Title className="main_title">Connect to your email provider</Title>

          {connectedProvider ? (
            <>
              <PanelDescription>
                Reach is compatible with a number of email providers. Select the
                one you want to use, provide your API keys, and you're done.
              </PanelDescription>
              <ConnectedProviderBlock>
                <ConnectedProviderInfo>
                  <ProviderIcon
                    $width="106px"
                    src={emailProviders[connectedProvider].iconSrc}
                    alt="email provider"
                  />
                  {connectionInfo.expired ? (
                    <ExpiredWarning>
                      <img
                        width="16"
                        src="/images/warning_icon.png"
                        alt="expired"
                      />
                      Expired
                    </ExpiredWarning>
                  ) : (
                    <IntegrationMethod>
                      {connectionInfo?.method === oAuthMethod
                        ? "Basic Mode"
                        : "Enterprise Mode"}
                    </IntegrationMethod>
                  )}
                  {connectionInfo?.createdAt && (
                    <IntegrationDate>
                      Configured&nbsp;
                      <span>
                        {displayIntegrationDate(connectionInfo.createdAt)}
                      </span>
                    </IntegrationDate>
                  )}
                </ConnectedProviderInfo>
                <BtnGroup
                  data-tooltip-id={
                    !isCompanyAdmin() && connectionInfo?.method !== oAuthMethod
                      ? `no-admin-connected-tooltip`
                      : null
                  }
                  data-tooltip-content="Please contact your company admin to change this setting"
                >
                  <OutlineBtn
                    disabled={
                      !isCompanyAdmin() &&
                      connectionInfo?.method !== oAuthMethod &&
                      isCustomProvider
                    }
                    onClick={() => handleCheckEmailHealth()}
                  >
                    Check domain health
                  </OutlineBtn>
                  <OutlineBtn
                    disabled={
                      !isCompanyAdmin() &&
                      connectionInfo?.method !== oAuthMethod
                    }
                    onClick={() => setIsConfirmDisconnectPopupOpen(true)}
                  >
                    Disconnect
                  </OutlineBtn>
                  <ConnectBtn
                    label="Edit Integration"
                    onClick={handleEditIntegration}
                    disabled={
                      !isCompanyAdmin() &&
                      connectionInfo?.method !== oAuthMethod
                    }
                  />
                </BtnGroup>
                <Tooltip
                  id={`no-admin-connected-tooltip`}
                  className="custom-tooltip"
                  place="left"
                />
              </ConnectedProviderBlock>
            </>
          ) : activeProvider ? (
            <ProviderSetting>
              <ProviderTitleBlock>
                <TitleBlock>
                  <ArrowBackIconBlock onClick={onCloseProviderSettings}>
                    <img
                      src="/images/arrow-campaign-description.svg"
                      alt="arrow-left-icon"
                    />
                  </ArrowBackIconBlock>
                  <ProviderTitle>
                    <div>EMAIL PROVIDER</div>
                    <span>{emailProviders[activeProvider].value}</span>
                  </ProviderTitle>
                </TitleBlock>
                <ProviderIcon
                  src={emailProviders[activeProvider].iconSrc}
                  alt="email provider"
                  width="88"
                />
              </ProviderTitleBlock>
              <Content>
                <ProviderDescription>
                  {emailProviders[activeProvider].description}
                </ProviderDescription>
                <MethodsTabs>
                  {getTabKeys().map((key) => (
                    <div key={key}>
                      <MethodTabBlock
                        data-active={activeMethod.value === methods[key].value}
                        data-tooltip-id={`${key}-admin-tooltip`}
                        data-tooltip-content="Please contact your company admin to change this setting"
                        onClick={() =>
                          (methods[key].value === oAuthMethod &&
                            isAllowSimpleConnect()) ||
                          (key === "enterprise" && isCompanyAdmin())
                            ? handleMethodClick(methods[key])
                            : null
                        }
                      >
                        <MethodTitle
                          disabled={
                            methods[key].value === oAuthMethod &&
                            !isAllowSimpleConnect()
                          }
                          $active={activeMethod.value === methods[key].value}
                          data-tooltip-id={`${key}-tooltip`}
                          data-tooltip-content="Simple connect is not allowed by company settings"
                        >
                          {methods[key].title}
                        </MethodTitle>
                        {methods[key].value === oAuthMethod &&
                          !isAllowSimpleConnect() && (
                            <Tooltip
                              id={`${key}-tooltip`}
                              place="right"
                              className="custom-tooltip"
                            />
                          )}
                      </MethodTabBlock>
                      {!isCompanyAdmin() && key === "enterprise" ? (
                        <Tooltip
                          id={`${key}-admin-tooltip`}
                          place="right"
                          className="custom-tooltip"
                        />
                      ) : null}
                    </div>
                  ))}
                </MethodsTabs>

                {activeProvider === emailProviders.office365.value &&
                activeMethod.value === oAuthMethod ? (
                  simpleConnect()
                ) : activeProvider === emailProviders.office365.value ? (
                  <>
                    <DoItForMeButton onClick={handleClickByDoItForMe} />
                    <label htmlFor="active-directory-id">
                      Active Directory ID
                    </label>
                    <ApiKeyInput
                      type="text"
                      id="active-directory-id"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.activeDirectoryId
                          : "Paste directory ID here..."
                      }
                      value={activeDirectoryId}
                      onChange={(e) => setActiveDirectoryId(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="app-registration-id">
                      App Registration ID
                    </label>
                    <ApiKeyInput
                      type="text"
                      id="app-registration-id"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.appRegistrationId
                          : "Paste app registration ID here..."
                      }
                      value={appRegistrationId}
                      onChange={(e) => setAppRegistrationId(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="secret-key">Secret Key</label>
                    <ApiKeyInput
                      type="password"
                      id="secret-key"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.secret
                          : "Paste secret key here..."
                      }
                      value={secretKey}
                      onChange={(e) => setSecretKey(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </>
                ) : null}

                {activeProvider === emailProviders.gsuite.value &&
                activeMethod.value === oAuthMethod ? (
                  simpleConnect()
                ) : activeProvider === emailProviders.gsuite.value ? (
                  <>
                    {<DoItForMeButton onClick={handleClickByDoItForMe} />}
                    <label htmlFor="client-email">Client Email</label>
                    <ApiKeyInput
                      type="text"
                      id="client-email"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.clientEmail
                          : "Paste client email here..."
                      }
                      value={clientEmail}
                      onChange={(e) => setClientEmail(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label htmlFor="private-key">Private Key</label>
                    <ApiKeyInput
                      type="text"
                      id="private-key"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.privateKey
                          : "Paste private key here..."
                      }
                      value={privateKey}
                      onChange={(e) => setPrivateKey(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </>
                ) : null}

                {isReconnecting &&
                  activeMethod.value !== methods.oAuth.value && (
                    <ActionsBtns>
                      <ConnectBtn
                        label="Connect"
                        onClick={handleConnect}
                        disabled={
                          (activeProvider === emailProviders.office365.value &&
                            (secretKey === "" ||
                              appRegistrationId === "" ||
                              activeDirectoryId === "")) ||
                          (activeProvider === emailProviders.gsuite.value &&
                            (clientEmail === "" || privateKey === ""))
                        }
                      />
                      <CancelButton onClick={onCloseProviderSettings}>
                        Cancel
                      </CancelButton>
                    </ActionsBtns>
                  )}
              </Content>
            </ProviderSetting>
          ) : (
            <>
              <PanelDescription className="side_panel_description">
                Reach is compatible with a number of email providers. Select the
                one you want to use, provide your API keys, and you're done.
              </PanelDescription>
              <ProvidersIcons>
                {Object.keys(emailProviders).map((key) => (
                  <div key={key}>
                    <ProviderTabBlock
                      $disabled={emailProviders[key].disabled}
                      data-tooltip-id={`${key}-tooltip`}
                      data-tooltip-content={
                        emailProviders[key].disabled
                          ? "Coming soon"
                          : !isCompanyAdmin() && !isAllowSimpleConnect()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        !emailProviders[key].disabled &&
                        (isCompanyAdmin() || isAllowSimpleConnect())
                          ? handleProviderClick(key)
                          : null
                      }
                    >
                      <ProviderIcon
                        src={emailProviders[key].tabIconSrc}
                        alt="email icon"
                      />
                    </ProviderTabBlock>
                    {emailProviders[key].disabled ||
                    !(isCompanyAdmin() || isAllowSimpleConnect()) ? (
                      <Tooltip
                        id={`${key}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </div>
                ))}
              </ProvidersIcons>
            </>
          )}
        </OverflowContent>
      )}

      <div>
        {connectedProvider &&
          availableProvidersIcons() &&
          availableProvidersIcons().length > 0 && (
            <div>
              <AvailableProvidersDivider />
              <AvailableProvidersTitle>
                Other available integrations for <span>Email System</span>
              </AvailableProvidersTitle>
              <AvailableProvidersIcons>
                {availableProvidersIcons().map((provider) => (
                  <React.Fragment key={provider}>
                    <ProviderIcon
                      src={emailProviders[provider].iconSrc}
                      alt="email icon"
                      $cursor="pointer"
                      data-tooltip-id={`${provider}-tooltip`}
                      data-tooltip-content={
                        !isCompanyAdmin() && !isAllowSimpleConnect()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        isCompanyAdmin() || isAllowSimpleConnect()
                          ? handleProviderClick(provider)
                          : null
                      }
                    />
                    {!isCompanyAdmin() && !isAllowSimpleConnect() ? (
                      <Tooltip
                        id={`${provider}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </React.Fragment>
                ))}
              </AvailableProvidersIcons>
            </div>
          )}
        {isWizard && (
          <ButtonGroup>
            <WizardStepControls stepConfig={stepConfig} goToTab={goToTab} />
          </ButtonGroup>
        )}
      </div>
    </Section>
  );
};

export default SetEmailProvider;

const Section = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PanelDescription = styled.div`
  font-size: 14px;
  width: 75%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 24px;
`;

const Title = styled.h3`
  font-family: "AlbertSansExtraBold";
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 5px;
`;

const ExpiredWarning = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const ProviderTitleBlock = styled.div`
  margin-bottom: 24px;
  margin-right: 90px;
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  display: flex;
  align-items: center;
`;

const ArrowBackIconBlock = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 40px;
  width: 20px;
  height: 17px;
  &::after {
    content: "";
    cursor: default;
    position: absolute;
    right: -90%;
    top: -50%;
    height: 58px;
    width: 1px;
    background-color: #e6e6e6;
  }
`;

const ProviderTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 800;
  span {
    font-size: 24px;
    font-weight: 400;
    color: black;
    text-transform: capitalize;
  }
`;

const ProviderDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 24px;
`;

const ProviderTabBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 180px;
  background-color: #f3f3f3;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 88px;
  }
  &:hover {
    background-color: ${({ $disabled }) => ($disabled ? "#f3f3f3" : "#471168")};

    img {
      content: url("${({ $hoverIconScr }) => $hoverIconScr}");
    }
  }
`;

const IntegrationMethod = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

const ConnectedProviderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
`;

const ProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ProviderIcon = styled.img`
  width: ${({ $width }) => ($width ? $width : "88px")};
  cursor: ${({ $cursor }) => ($cursor ? $cursor : "default")};
`;

const ApiKeyInput = styled.input`
  margin-top: 4px;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 0px 12px 16px;
  height: 38px;
  max-width: 600px;

  &:focus-visible {
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    height: 38px;
    max-width: 600px;
  }
  &:disabled {
    background-color: white;
  }
`;

const ProviderSetting = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.btn_border_color};
  }
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 20px;
`;

const OverflowContent = styled.div`
  overflow: auto;
`;

const ActionsBtns = styled.div`
  display: flex;
  align-items: center;
`;

const MethodsTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32.75px;
  margin-bottom: 24px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.divider_color};
  }
`;

const MethodTabBlock = styled.div`
  position: relative;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  ${(props) =>
    props["data-active"]
      ? `
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    z-index: 9;
  }`
      : ""};
`;

const MethodTitle = styled.div`
  font-size: 14px;
  font-weight: ${({ $active }) => ($active ? "700" : "400")};
`;

const Content = styled.div`
  margin-left: 60px;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const AvailableProvidersDivider = styled.div`
  width: 34px;
  height: 4px;
  background-color: black;
  opacity: 0.1;
`;

const AvailableProvidersTitle = styled.div`
  margin-top: 13px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  span {
    font-weight: 700;
    font-family: "AlbertSansExtraBold";
  }
`;

const AvailableProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
`;

const IntegrationDate = styled.div`
  font-size: 13px;
  font-weight: 400;

  span {
    font-weight: 700;
  }

  @media (max-width: 1490px) {
    display: none;
  }
`;

const CancelButton = styled.div`
  color: #4640de;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
`;
