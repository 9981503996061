/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../../InfiniteScroll";
import Spinner from "../../../../Spinner";
import Loader from "../../../../Loader";
import theme from "../../../../../theme";
import { getUserActivity } from "../../../../../services/reports";
import { getFilters } from "../../../../../services/campaigns";
import { CRM_OPTIONS, CRMS } from "../../../../../utils/constants";
import { MultiSelect } from "react-multi-select-component";
import { sendMessageToExtension } from "../../../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../../../utils/getUserFromLocalStorage";
import { displayDate } from "../../../../../utils/utils";
import { exportToCSV } from "../../../../../utils/export";

function UserActivityTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    crm: [],
    company: [],
  });
  const [sort, setSort] = useState({ sort: null, order: null });

  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const userInfo = getUserFromLocalStorage();
  const isAdmin = userInfo ? userInfo.role === "admin" : false;

  const fetchUsers = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (params?.newSort || sort.sort) {
      data.sort = (params?.newSort || sort).sort;
      data.order = (params?.newSort || sort).order;
    }

    if (Object.keys(params?.newFilters || filters).length > 0) {
      data.filters = params?.newFilters || filters;
    }

    setIsLoading(!data.offset);

    const result = await getUserActivity(data);

    setIsLoading(false);

    if (result.success) {
      setUsers(
        params?.clearAfterSearch
          ? result.result.users
          : [...users, ...result.result.users]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.users.length
          : dataLength + result.result.users.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch users",
        },
      });
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField === sort.sort) {
      if (sort.order === "asc") {
        newSort = { ...sort, order: "desc" };
      } else {
        newSort = { sort: null, order: null };
      }
    } else {
      newSort = { sort: sortField, order: "asc" };
    }
    setSort(newSort);
    await fetchUsers({ clearAfterSearch: true, newSort });
  };

  const handleChangeFilter = async (newFilters) => {
    setFilters(newFilters);
    if (!isAdmin) {
      newFilters.crm = CRMS.BULLHORN;
      newFilters.company = userInfo.companyId;
    }
    await fetchUsers({ clearAfterSearch: true, newFilters });
  };

  const handleSelectFilter = (filterName, selOptions) => {
    const newFilters = {
      ...filters,
      [filterName]: selOptions.map((o) => o.value),
    };
    handleChangeFilter(newFilters);
  };

  const fetchByScroll = async () => {
    await fetchUsers({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchUsers();
  };

  useEffect(() => {
    if (isAdmin) {
      const loadCompanyFilter = async () => {
        const res = await getFilters({ filter: ["companies"] });
        if (res.success) {
          setCompanies(
            (res.result?.companies || []).map((c) => ({
              value: c.id,
              label: c.name,
            }))
          );
        }
      };
      loadCompanyFilter();
    }
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const headers = [
    { name: "company", label: "Company" },
    { name: "user", label: "User" },
    { name: "num_campaigns", label: "# of Campaigns" },
    { name: "last_campaign_date", label: "Last Campaign Date" },
    { name: "last_campaign_name", label: "Last Campaign Name" },
    { name: "last_campaign_status", label: "Last Campaign Status" },
  ];

  const exportToFile = async () => {
    exportToCSV(headers, users, "users.csv");
  };

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {headers.map(({ name, label }) => (
            <TableHeadData
              key={name}
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : {})}
            >
              <TableHeadDataSort>
                {label}
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No users to show</EmptyTableData>
            </TableRow>
          ) : (
            users.map((user, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{user.company}</TableData>
                <TableData>{user.user}</TableData>
                <TableData>{user.num_campaigns}</TableData>
                <TableData>{displayDate(user.last_campaign_date)}</TableData>
                <TableData>{user.last_campaign_name}</TableData>
                <TableData>{user.last_campaign_status}</TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container $isLoading={isLoading}>
      <Filters>
        {isAdmin && (
          <Filter>
            <FilterLabel>Company</FilterLabel>
            <MultiSelect
              options={companies}
              value={companies.filter((c) => filters.company.includes(c.value))}
              onChange={(selOptions) =>
                handleSelectFilter("company", selOptions)
              }
              labelledBy="Company"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
            />
          </Filter>
        )}
        {isAdmin && (
          <Filter>
            <FilterLabel>CRM</FilterLabel>
            <MultiSelect
              options={CRM_OPTIONS}
              value={CRM_OPTIONS.filter((c) => filters.crm.includes(c.value))}
              onChange={(selOptions) => handleSelectFilter("crm", selOptions)}
              labelledBy="CRM"
              hasSelectAll={false}
              disableSearch={true}
              closeOnChangedValue={true}
              display="chip"
            />
          </Filter>
        )}
        <OutlineCampaignBtn disabled={isLoading} onClick={exportToFile}>
          Export
        </OutlineCampaignBtn>
      </Filters>
      <Content ref={tableContRef}>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <OverflowContent id="table_layout">
            {hasMore ? (
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchByScroll}
                hasMore={hasMore}
                scrollableTarget={"table_layout"}
                style={{ overflowY: "hidden" }}
                loader={
                  <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                }
              >
                {table}
              </InfiniteScroll>
            ) : (
              table
            )}
          </OverflowContent>
        )}
      </Content>
    </Container>
  );
}

export default UserActivityTable;

const Container = styled.div`
  height: calc(100% - 55px);
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           background: transparent;
           z-index: 1;
         }`
      : ""}
`;

const Content = styled.div`
  height: calc(100% - 90px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Filters = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const OutlineCampaignBtn = styled.button`
  margin-left: auto;
  margin-top: auto;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 0 22px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;
