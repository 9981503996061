/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Loader from "../../../Loader";
import Table from "./AutoWarmTable";
import MailboxPopup from "./AutoWarmMailboxPopup";
import {
  fetchDeleteAutoWarmMailboxes as deleteAutoWarmMailbox,
  fetchGetAutoWarmMailboxes as getAutoWarmMailboxes,
  fetchUpdateAutoWarmMailboxes as updateAutoWarmMailbox,
} from "../../../../services/queries";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import ConfirmPopup from "../../../ConfirmPopup";
import { AUTO_WARM_MAILBOX_PROVIDERS } from "../../../../utils/constants";

function AutoWarmWrapper() {
  const [isLoading, setIsLoading] = useState(false);
  const [mailboxes, setMailboxes] = useState([]);
  const [mailboxPopupData, setMailboxPopupData] = useState(null);
  const [mailboxConfirmDelData, setMailboxConfirmDelData] = useState(null);

  const fetchMailboxes = async (needToUpdatePopupData) => {
    setIsLoading(true);

    const result = await getAutoWarmMailboxes();

    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch auto-warm mailboxes",
        },
      });
      return;
    }

    const { autoWarmMailboxes } = result.result;
    setMailboxes(autoWarmMailboxes);

    if (needToUpdatePopupData && mailboxPopupData?.id) {
      const openedMailbox = autoWarmMailboxes.find(
        (m) => m.id === mailboxPopupData.id
      );
      if (openedMailbox) {
        setMailboxPopupData(openedMailbox);
      }
    }
  };

  useEffect(() => {
    fetchMailboxes();
  }, []);

  const handleOpenMailboxPopup = (mailbox = {}) => {
    setMailboxPopupData(mailbox);
  };

  const handleCloseMailboxPopup = () => {
    setMailboxPopupData(null);
  };

  const handleConfirmDeleteMailbox = (mailbox) => {
    setMailboxConfirmDelData(mailbox);
  };

  const handleDeleteMailbox = async () => {
    const { id } = mailboxConfirmDelData;

    setMailboxConfirmDelData(null);
    setIsLoading(true);

    const result = await deleteAutoWarmMailbox({ id: [id] });

    setIsLoading(false);

    if (!result.success) {
      console.log("handleDeleteMailbox", result.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            "Something went wrong when trying to delete mailbox",
        },
      });
      return;
    }

    await fetchMailboxes();
  };

  const handleSaveMailbox = async (data, isConnectOAuthAfterCreate) => {
    if (!isConnectOAuthAfterCreate) {
      setMailboxPopupData(null);
    }
    setIsLoading(true);

    const result = await updateAutoWarmMailbox(data);

    setIsLoading(false);

    if (!result.success) {
      console.log("handleSaveMailbox", result.message);
      setMailboxPopupData(null);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            `Something went wrong when trying to ${
              data.id ? "update" : "create"
            } mailbox`,
        },
      });
      return;
    }

    if (isConnectOAuthAfterCreate) {
      const { autoWarmMailbox } = result.result;
      setMailboxPopupData({ ...autoWarmMailbox, needToConnectOAuth: true });
    }

    await fetchMailboxes();
  };

  const handleSuccessConnectIntegration = async (integration) => {
    const newData = {
      ...mailboxPopupData,
      integration,
    };
    delete newData.needToConnectOAuth;
    setMailboxPopupData(newData);
    await fetchMailboxes(true);
  };

  const handleDisconnectIntegration = async () => {
    setIsLoading(true);

    const result = await updateAutoWarmMailbox({
      id: mailboxPopupData.id,
      needToDisconnectIntegration: true,
    });

    setIsLoading(false);

    if (!result.success) {
      console.log("handleDisconnectIntegration", result.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            `Something went wrong when trying to disconnect integration`,
        },
      });
      return;
    }

    const isOffice365 =
      mailboxPopupData.integration.type ===
      AUTO_WARM_MAILBOX_PROVIDERS.office365;
    sendMessageToExtension({
      message: "show-success-message",
      data: {
        message: `Please revoke the "SalesStream.ai Reach" app's access to information in your ${
          isOffice365 ? "Office 365 " : "Google"
        } Account settings.`,
        title: "",
      },
    });
    setTimeout(() => {
      window.open(
        isOffice365
          ? "https://account.live.com/consent/Manage"
          : "https://myaccount.google.com/connections",
        "_blank"
      );
    }, 1500);

    setMailboxPopupData({ ...mailboxPopupData, integration: {} });

    await fetchMailboxes(true);
  };

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Loader parentSize={true} padding="0" />
        </LoaderWrapper>
      )}

      <Content>
        <Title>AutoWarm</Title>

        <BtnToolbar>
          <AddBtn
            onClick={() => handleOpenMailboxPopup()}
            disabled={isLoading || !!mailboxPopupData}
          >
            Add mailbox
          </AddBtn>
        </BtnToolbar>

        {!!mailboxPopupData && (
          <MailboxPopup
            data={mailboxPopupData}
            onClose={handleCloseMailboxPopup}
            onSave={handleSaveMailbox}
            onDisconnect={handleDisconnectIntegration}
            onSuccessConnect={handleSuccessConnectIntegration}
          />
        )}

        {!!mailboxConfirmDelData && (
          <ConfirmPopup
            title="Delete mailbox"
            description={
              <>
                Are you sure you want to delete{" "}
                <strong>{mailboxConfirmDelData.emailAddress}</strong> mailbox?
              </>
            }
            onCancel={() => setMailboxConfirmDelData(null)}
            onConfirm={handleDeleteMailbox}
            confirmLabel="Delete"
          />
        )}

        <Table
          data={mailboxes}
          onSave={handleSaveMailbox}
          onDelete={handleConfirmDeleteMailbox}
          onOpenPopup={handleOpenMailboxPopup}
        />
      </Content>
    </Container>
  );
}

export default AutoWarmWrapper;

const Container = styled.div`
  padding: 0 20px 0 0;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: #ffffff47; */
  z-index: 100;
`;

const BtnToolbar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;

const AddBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  border: none;
  font-weight: 800;
  margin-bottom: 20px;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
  }
  &:not([disabled]):hover {
    opacity: 0.85;
  }
`;
