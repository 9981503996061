import { styled } from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  height: 100%;
`;

export const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const CreatePostButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  color: ${({ theme }) => theme.colors.white};
  height: 40px;
  padding: 0 25px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 25px;

  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-right: 20px;
  z-index: 100;
`;
