import React, { useState } from "react";
import { createIntegration } from "../../services/integrations";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import Loader from "../Loader";
import ConnectBtn from "../ConnectBtn";
import ConfirmPopup from "../ConfirmPopup";

import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { displayIntegrationDate } from "./utils";
import { BtnGroup, ConnectedProviderBlock, OutlineBtn } from "./styles";
import WizardStepControls from "./WizardStepControls";
import OpenAiModelsPopup from "./OpenAiModelsPopup";
import {
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../../api/localStorage";

const aiModels = {
  OpenAI: {
    iconSrc: "images/OpenAI-Logo.png",
    value: "OpenAI",
    description:
      "To integrate Reach with OpenAI, we need your OpenAI API key. Once this is added to your account, Reach will be able to use your personal OpenAI account for message generation.",
  },
};

const SetAiModel = ({
  onConnected,
  isConnected,
  isWizard,
  goToTab,
  connectionFields,
  onConnect,
  onDisconnect,
  stepConfig,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connectionInfo, setConnectionInfo] = useState(connectionFields);

  const [activeProvider, setActiveProvider] = useState(null);
  const [activeProviderAPIKey, setActiveProviderAPIKey] = useState("");

  const [connectedProvider, setConnectedProvider] = useState(
    connectionFields && connectionFields.type ? connectionFields.type : null
  );
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isConfirmDisconnectPopupOpen, setIsConfirmDisconnectPopupOpen] =
    useState(false);

  const [isOpenAiModelsPopupOpened, setIsOpenAiModelsPopupOpened] =
    useState(false);

  const handleProviderClick = (key) => {
    setIsReconnecting(true);
    setActiveProvider(key);
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  const onCloseProviderSettings = () => {
    setActiveProvider(null);
    setConnectedProvider(
      connectionFields && connectionFields.type ? connectionFields.type : null
    );
    setActiveProviderAPIKey("");
    setIsReconnecting(false);
  };

  const handleEditIntegration = () => {
    setActiveProvider(connectedProvider);
    setConnectedProvider(null);
    setIsReconnecting(true);
  };

  const onUpdateConnectionInfo = (data) => {
    const companyInfo = getObjectFromLocalStorage("companyInfo");
    if (companyInfo) {
      saveObjectToLocalStorage("companyInfo", {
        company: {
          ...companyInfo.company,
          aiModel: { ...data, apiKey: undefined },
        },
      });
    }
    setConnectionInfo(data);
  };

  const handleDisconnect = async () => {
    setIsConfirmDisconnectPopupOpen(false);
    setActiveProvider(null);
    const success = !onDisconnect || (await onDisconnect(connectedProvider));
    if (success) {
      onUpdateConnectionInfo({});
      setConnectedProvider(null);
      setIsReconnecting(true);
    }
  };

  const handleConnect = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "These values can be set only by company admin",
        },
      });
      return;
    }

    const data = {
      type: "aiModel",
      connectionKeys: {
        type: activeProvider,
        apiKey: activeProviderAPIKey,
      },
    };

    setIsLoading(true);
    const result = await createIntegration(data);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const newData = { ...result.integrations?.integration?.aiModel };
    Object.keys(data.connectionKeys).forEach((key) => {
      if (!newData[key]) {
        newData[key] = data.connectionKeys[key].replace(/[^-]/g, "*");
      }
    });
    if (typeof newData.expired !== "boolean") {
      newData.expired = false;
    }
    if (!newData.type) {
      newData.type = activeProvider;
    }
    if (!newData.name) {
      newData.name = activeProvider;
    }
    if (!newData.createdAt) {
      newData.createdAt = new Date().toISOString();
    }

    onUpdateConnectionInfo(newData);
    // setActiveProviderAPIKey("");

    // setIsReconnecting(false);
    // setConnectedProvider(activeProvider);
    setIsLoading(false);
    onConnected("aiModel");
    onConnect(newData);
  };

  const availableProvidersIcons = () => {
    if (connectedProvider)
      return Object.keys(aiModels).filter(
        (key) => key !== connectedProvider && !aiModels[key].disabled
      );
    return [];
  };

  return (
    <Section>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <OverflowContent>
          {isConfirmDisconnectPopupOpen && (
            <ConfirmPopup
              title="Are you sure?"
              description={`You’re about to disconnect ${connectedProvider} integration. Are you sure you want to proceed?`}
              onConfirm={handleDisconnect}
              onCancel={() => setIsConfirmDisconnectPopupOpen(false)}
            />
          )}
          <Title className="main_title">Use your own OpenAI keys</Title>

          {connectedProvider ? (
            <>
              <PanelDescription className="side_panel_description">
                You can use Reach as your AI Model provider or use your own
                OpenAI account. When you use your own keys, Reach does not
                charge you for AI credits
              </PanelDescription>

              <ConnectedProviderBlock>
                <ProviderIcon
                  $width="106px"
                  src={aiModels[connectedProvider].iconSrc}
                  alt="ai model icon"
                />
                {connectionInfo.expired && (
                  <ExpiredWarning>
                    <img
                      width="16"
                      src="/images/warning_icon.png"
                      alt="expired"
                    />
                    Expired
                  </ExpiredWarning>
                )}
                {connectionInfo?.createdAt && (
                  <IntegrationDate>
                    Configured&nbsp;
                    <span>
                      {displayIntegrationDate(connectionInfo.createdAt)}
                    </span>
                  </IntegrationDate>
                )}
                <BtnGroup
                  data-tooltip-id={
                    !isCompanyAdmin() ? `no-admin-connected-tooltip` : null
                  }
                  data-tooltip-content="Please contact your company admin to change this setting"
                >
                  <OutlineBtn
                    disabled={!isCompanyAdmin()}
                    onClick={() => setIsConfirmDisconnectPopupOpen(true)}
                  >
                    Disconnect
                  </OutlineBtn>
                  <ConnectBtn
                    label="Edit Integration"
                    onClick={handleEditIntegration}
                    disabled={!isCompanyAdmin()}
                  />
                </BtnGroup>
                <Tooltip
                  id={`no-admin-connected-tooltip`}
                  className="custom-tooltip"
                  place="left"
                />
              </ConnectedProviderBlock>
            </>
          ) : activeProvider ? (
            <ProviderSetting>
              <ProviderTitleBlock>
                <TitleBlock>
                  <ArrowBackIconBlock onClick={onCloseProviderSettings}>
                    <img
                      src="/images/arrow-campaign-description.svg"
                      alt="arrow-left-icon"
                    />
                  </ArrowBackIconBlock>
                  <ProviderTitle>
                    <div>AI Model</div>
                    <span>{aiModels[activeProvider].value}</span>
                  </ProviderTitle>
                </TitleBlock>
                <ProviderIcon
                  src={aiModels[activeProvider].iconSrc}
                  alt="ai model icon"
                />
              </ProviderTitleBlock>
              <Content>
                <ProviderDescription>
                  {aiModels[activeProvider].description}
                </ProviderDescription>

                <AiModelFieldsContainer>
                  <ProviderSetting $width="525px">
                    <label htmlFor="api-key">API Key</label>
                    <ApiKeyInput
                      type="text"
                      id="api-key"
                      placeholder={
                        connectionInfo && !isReconnecting
                          ? connectionInfo.apiKey
                          : "Paste your API Key here..."
                      }
                      disabled={
                        (!isReconnecting && isConnected) || !isCompanyAdmin()
                      }
                      value={activeProviderAPIKey}
                      onChange={(e) => setActiveProviderAPIKey(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </ProviderSetting>
                  {!!connectionInfo?.openAiModels?.length && (
                    <ReconnectBtn
                      onClick={() => setIsOpenAiModelsPopupOpened(true)}
                    >
                      Select Model
                    </ReconnectBtn>
                  )}
                  {isOpenAiModelsPopupOpened && (
                    <OpenAiModelsPopup
                      onClose={(fields) => {
                        if (fields) {
                          onUpdateConnectionInfo({
                            ...connectionInfo,
                            ...fields,
                          });
                        }
                        setIsOpenAiModelsPopupOpened(false);
                      }}
                      data={connectionInfo}
                      isWizard={isWizard}
                    />
                  )}
                </AiModelFieldsContainer>

                <ActionsBtns>
                  {isReconnecting && (
                    <>
                      <ConnectBtn
                        label="Connect"
                        onClick={handleConnect}
                        disabled={
                          activeProviderAPIKey === "" || !isCompanyAdmin()
                        }
                      />
                      <CancelButton onClick={onCloseProviderSettings}>
                        Cancel
                      </CancelButton>
                    </>
                  )}
                </ActionsBtns>
              </Content>
            </ProviderSetting>
          ) : (
            <>
              <PanelDescription className="side_panel_description">
                Reach is compatible with a number of AI Models. Select the one
                you want to use, provide your API keys, and you're done.
              </PanelDescription>
              <ProvidersIcons>
                {Object.keys(aiModels).map((key) => (
                  <div key={key}>
                    <ProviderTabBlock
                      $disabled={aiModels[key].disabled}
                      data-tooltip-id={`${key}-tooltip`}
                      data-tooltip-content={
                        aiModels[key].disabled
                          ? "Coming soon"
                          : !isCompanyAdmin()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        !aiModels[key].disabled && isCompanyAdmin()
                          ? handleProviderClick(key)
                          : null
                      }
                    >
                      <ProviderIcon
                        src={aiModels[key].iconSrc}
                        alt="ai model icon"
                      />
                    </ProviderTabBlock>
                    {aiModels[key].disabled || !isCompanyAdmin() ? (
                      <Tooltip
                        id={`${key}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </div>
                ))}
              </ProvidersIcons>
            </>
          )}
        </OverflowContent>
      )}

      <div>
        {connectedProvider &&
          availableProvidersIcons() &&
          availableProvidersIcons().length > 0 && (
            <div>
              <AvailableProvidersDivider />
              <AvailableProvidersTitle>
                Other available integrations for <span>AI Models</span>
              </AvailableProvidersTitle>
              <AvailableProvidersIcons>
                {availableProvidersIcons().map((provider) => (
                  <div key={provider}>
                    <ProviderIcon
                      src={aiModels[provider].iconSrc}
                      alt="ai model icon"
                      $cursor="pointer"
                      data-tooltip-id={`${provider}-tooltip`}
                      data-tooltip-content={
                        !isCompanyAdmin()
                          ? "Please contact your company admin to change this setting"
                          : null
                      }
                      onClick={() =>
                        isCompanyAdmin() ? handleProviderClick(provider) : null
                      }
                    />
                    {!isCompanyAdmin() ? (
                      <Tooltip
                        id={`${provider}-tooltip`}
                        place="right"
                        className="custom-tooltip"
                      />
                    ) : null}
                  </div>
                ))}
              </AvailableProvidersIcons>
            </div>
          )}
        {isWizard && (
          <ButtonGroup>
            <WizardStepControls stepConfig={stepConfig} goToTab={goToTab} />
          </ButtonGroup>
        )}
      </div>
    </Section>
  );
};

export default SetAiModel;

const Section = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PanelDescription = styled.div`
  font-size: 14px;
  width: 75%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 24px;
`;

const ExpiredWarning = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const Title = styled.h3`
  font-family: "AlbertSansExtraBold";
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 5px;
`;

const ProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ProviderTabBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 180px;
  background-color: #f3f3f3;
  border-radius: 5px;
  cursor: pointer;
  svg {
    width: 88px;
  }
  path {
    fill: black;
  }
  &:hover {
    background-color: ${({ $disabled }) => ($disabled ? "#f3f3f3" : "#471168")};

    path {
      ${({ $disabled }) => !$disabled && "fill: white;"}
    }
  }
`;

const ArrowBackIconBlock = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 40px;
  width: 20px;
  height: 17px;
  &::after {
    content: "";
    cursor: default;
    position: absolute;
    right: -90%;
    top: -50%;
    height: 58px;
    width: 1px;
    background-color: #e6e6e6;
  }
`;

const ProviderTitleBlock = styled.div`
  margin-bottom: 24px;
  margin-right: 90px;
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  display: flex;
  align-items: center;
`;

const ProviderTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 800;
  span {
    font-size: 24px;
    font-weight: 400;
    color: black;
    text-transform: capitalize;
  }
`;

const ProviderDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 24px;
`;

const ProviderIcon = styled.img`
  width: ${({ $width }) => ($width ? $width : "88px")};
  cursor: ${({ $cursor }) => ($cursor ? $cursor : "default")};
`;

const ApiKeyInput = styled.input`
  margin-top: 4px;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 0px 12px 16px;
  height: 38px;
  max-width: 600px;

  &:focus-visible {
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    height: 38px;
    max-width: 600px;
  }
  &:disabled {
    background-color: white;
  }
`;

const ProviderSetting = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => ($width ? $width : "auto")};
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.btn_border_color};
  }
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 20px;
`;

const OverflowContent = styled.div`
  overflow: auto;
`;

const ActionsBtns = styled.div`
  display: flex;
  align-items: center;
`;

const ReconnectBtn = styled.div`
  font-size: 14px;
  color: #4640de;
  margin-left: 20px;
  cursor: pointer;
`;

const AiModelFieldsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 60px;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const AvailableProvidersDivider = styled.div`
  width: 34px;
  height: 4px;
  background-color: black;
  opacity: 0.1;
`;

const AvailableProvidersTitle = styled.div`
  margin-top: 13px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  span {
    font-weight: 700;
    font-family: "AlbertSansExtraBold";
  }
`;

const AvailableProvidersIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
`;

const IntegrationDate = styled.div`
  font-size: 13px;
  font-weight: 400;

  span {
    font-weight: 700;
  }
`;

const CancelButton = styled.div`
  color: #4640de;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
`;
