import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import SelectRecipients from "../../CreateCampaign/ContentSteps/SelectRecipients";

function AddContacts({ onCancel, onConfirm, campaign, candidateList }) {
  const location = useLocation();
  const { isRecreatingCampaignIframe } = queryString.parse(location.search);

  const [campaignData, setCampaignData] = useState({
    crmType: campaign.crmType,
    type: campaign.crmData?.campaign_type,
    isFullSequenceCampaign: campaign.isFullSequenceCampaign,
    candidatesLength: 0,
    isCreated: true,
  });

  const handleConfirm = () => {
    onConfirm(
      campaignData.candidates?.filter((c) => !c.isInvalid && !c.isAlreadyAdded)
    );
  };

  return (
    <Container>
      <SelectRecipients
        campaign={campaignData}
        setCampaign={setCampaignData}
        handleDecreaseStep={onCancel}
        handleIncreaseStep={handleConfirm}
        crm={campaign.crmType}
        isRecreatingCampaignIframe={isRecreatingCampaignIframe}
        isAlreadyAdded={(c) =>
          candidateList.some((cl) => cl.externalId === c.id)
        }
        prevBtnLabel="Cancel"
        nextBtnLabel="Add"
      />
    </Container>
  );
}

export default AddContacts;

const Container = styled.div`
  position: relative;
  margin: 28px 54px 0;
  padding: 0 0 28px;
  height: calc(100vh - 58px - 28px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 30px 0px #0000001a;
  overflow-y: auto;
`;
