/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import theme from "../../../../theme";
import {
  AUTO_WARM_MAILBOX_PROVIDERS,
  AUTO_WARM_MAILBOX_STATUSES,
} from "../../../../utils/constants";
import { sortRows } from "../../../../utils/utils";

function AutoWarmTable({ data, onOpenPopup, onSave, onDelete }) {
  const [mailboxes, setMailboxes] = useState([]);
  const [sort, setSort] = useState({ sort: null, order: null });

  const columns = [
    { name: "emailAddress", label: "Email Address", sortable: true },
    {
      name: "provider",
      label: "Provider",
      sortable: true,
      render: (row) => {
        const { provider } = row;
        return provider === AUTO_WARM_MAILBOX_PROVIDERS.gsuite
          ? "Gmail"
          : provider.replace(/^[a-z]/, (match) => match.toUpperCase());
      },
    },
    { name: "emailsSent", label: "Emails Sent", sortable: true },
    { name: "status", label: "Status", sortable: true },
    {
      label: "Actions",
      skipped: !mailboxes.length,
      render: (row) => {
        const { id, status } = row;
        return (
          <Actions>
            {status !== AUTO_WARM_MAILBOX_STATUSES.warmed && (
              <>
                <ActionBtn onClick={() => onOpenPopup(row)}>Edit</ActionBtn>
                <ActionBtn
                  onClick={() =>
                    onSave({
                      id,
                      status:
                        status === AUTO_WARM_MAILBOX_STATUSES.enabled
                          ? AUTO_WARM_MAILBOX_STATUSES.disabled
                          : AUTO_WARM_MAILBOX_STATUSES.enabled,
                    })
                  }
                >
                  {status === AUTO_WARM_MAILBOX_STATUSES.enabled
                    ? "Disable"
                    : "Enable"}
                </ActionBtn>
              </>
            )}
            <ActionBtn onClick={() => onDelete(row)}>Delete</ActionBtn>
          </Actions>
        );
      },
    },
  ];

  const sortMailboxes = (rows, sortParams) => {
    const { sort: sortField, order: sortOrder } = sortParams || sort;

    const columnRender = columns.find((c) => c.name === sortField)?.render;

    setMailboxes(sortRows(rows, sortField, sortOrder, columnRender));
  };

  useEffect(() => {
    sortMailboxes(data);
  }, [data]);

  const handleClickSort = async (sortField) => {
    const newSort =
      sortField !== sort.sort
        ? { sort: sortField, order: "asc" }
        : { ...sort, order: sort.order !== "asc" ? "asc" : "desc" };
    setSort(newSort);
    sortMailboxes(mailboxes, newSort);
  };

  const dataLength = mailboxes.length;
  const withSort = dataLength > 1;

  return (
    <Content>
      <OverflowContent>
        <Table>
          <TableHead>
            <TableRow $bgColor="#f3f2f2">
              {columns.map(
                ({ name, label, sortable = false, skipped = false }, index) =>
                  !skipped && (
                    <TableHeadData
                      key={index}
                      {...(withSort && sortable
                        ? {
                            $cursorPointer: true,
                            onClick: () => handleClickSort(name),
                          }
                        : {})}
                    >
                      <TableHeadDataSort>
                        {label}
                        {withSort && sortable && (
                          <SortIcon
                            src={`/images/sort-${
                              sort.sort === name ? `${sort.order}-` : ""
                            }icon.png`}
                            alt="sort"
                          />
                        )}
                      </TableHeadDataSort>
                    </TableHeadData>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              {!dataLength ? (
                <TableRow>
                  <EmptyTableData colSpan={100}>
                    No mailboxes to show
                  </EmptyTableData>
                </TableRow>
              ) : (
                mailboxes.map((mailbox, rowIdx) => (
                  <TableRow
                    key={`${rowIdx}-r`}
                    $borderBottom={`1px solid ${theme.colors.divider_color}`}
                  >
                    {columns.map(
                      ({ name, render, skipped = false }, colIdx) =>
                        !skipped && (
                          <TableData key={`${rowIdx}-${colIdx}`}>
                            {render ? render(mailbox) : mailbox[name]}
                          </TableData>
                        )
                    )}
                  </TableRow>
                ))
              )}
            </React.Fragment>
          </TableBody>
        </Table>
      </OverflowContent>
    </Content>
  );
}

export default AutoWarmTable;

const Content = styled.div`
  height: calc(100% - 55px - 60px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;

const ActionBtn = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.colors.saturated_purple};
  cursor: pointer;
  &:hover {
    text-shadow: 0 0 black;
  }
`;
