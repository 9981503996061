/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../../InfiniteScroll";
import Spinner from "../../../../Spinner";
import Loader from "../../../../Loader";
import theme from "../../../../../theme";
import { getUndeliverableEmails } from "../../../../../services/reports";
import { sendMessageToExtension } from "../../../../../utils/postToExtension";
import { exportToCSV } from "../../../../../utils/export";

function UndeliverableEmailsTable({ openCampaignsWithFilter }) {
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ sort: "email", order: "asc" });

  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const fetchEmails = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (params?.newSort || sort.sort) {
      data.sort = (params?.newSort || sort).sort;
      data.order = (params?.newSort || sort).order;
    }

    setIsLoading(!data.offset);

    const result = await getUndeliverableEmails(data);

    setIsLoading(false);

    if (result.success) {
      setEmails(
        params?.clearAfterSearch
          ? result.result.emails
          : [...emails, ...result.result.emails]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.emails.length
          : dataLength + result.result.emails.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }

      return result.result.meta.hasMore;
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch emails",
        },
      });

      return false;
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField === sort.sort) {
      if (sort.order === "asc") {
        newSort = { ...sort, order: "desc" };
      } else {
        newSort = { sort: null, order: null };
      }
    } else {
      newSort = { sort: sortField, order: "asc" };
    }
    setSort(newSort);
    await fetchEmails({ clearAfterSearch: true, newSort });
  };

  const fetchByScroll = async () => {
    await fetchEmails({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchEmails();
  };

  useEffect(() => {
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const headers = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email address" },
    { name: "companyName", label: "Company Name" },
    { name: "status", label: "Status" },
  ];

  const exportToFile = () => {
    exportToCSV(headers, emails, "undeliverable-emails.csv");
  };

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {headers.map(({ name, label }) => (
            <TableHeadData
              key={name}
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : {})}
            >
              <TableHeadDataSort>
                {label}
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No emails to show</EmptyTableData>
            </TableRow>
          ) : (
            emails.map((email, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{email.name || "n/a"}</TableData>
                <TableData>{email.email}</TableData>
                <TableData>{email.companyName}</TableData>
                <TableData>{email.status}</TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container>
      <Content ref={tableContRef}>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <>
            <OutlineCampaignBtn disabled={isLoading} onClick={exportToFile}>
              Export
            </OutlineCampaignBtn>
            <OverflowContent id="table_layout">
              {hasMore ? (
                <InfiniteScroll
                  dataLength={dataLength}
                  next={fetchByScroll}
                  hasMore={hasMore}
                  scrollableTarget={"table_layout"}
                  style={{ overflowY: "hidden" }}
                  loader={
                    <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                  }
                >
                  {table}
                </InfiniteScroll>
              ) : (
                table
              )}
            </OverflowContent>
          </>
        )}
      </Content>
    </Container>
  );
}

export default UndeliverableEmailsTable;

const Container = styled.div`
  height: calc(100% - 55px);
`;

const Content = styled.div`
  height: 100%;
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const OutlineCampaignBtn = styled.button`
  margin-left: 100%;
  transform: translateX(-100%);
  margin-bottom: 12px;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 0 22px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;
