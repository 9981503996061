import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ConnectBtn from "../../../ConnectBtn";
import CustomSelect from "../../../CustomSelect";
import {
  AUTO_WARM_MAILBOX_PROVIDERS,
  AUTO_WARM_MAILBOX_STATUSES,
} from "../../../../utils/constants";
import SimpleConnect from "../Integrations/SimpleConnect";
import { OutlineBtn } from "../../../SetIntegrations/styles";
import SuggestionPopup from "../../../SuggestionPopup";
import ConfirmPopup from "../../../ConfirmPopup";

const FIELDS = [
  { name: "firstname", label: "First Name" },
  { name: "lastname", label: "Last Name" },
  { name: "emailAddress", label: "Email Address", type: "email" },
  { name: "password", label: "Password", type: "password" },
  {
    name: "provider",
    label: "Provider",
    type: "select",
    options: Object.values(AUTO_WARM_MAILBOX_PROVIDERS)
      .sort()
      .map((value) => ({
        value,
        label:
          value === AUTO_WARM_MAILBOX_PROVIDERS.gsuite
            ? "Gmail"
            : value.replace(/^[a-z]/, (match) => match.toUpperCase()),
      })),
  },
];

const AutoWarmMailboxPopup = ({
  data,
  onClose,
  onSave,
  onDisconnect,
  onSuccessConnect,
}) => {
  const isEditMode = !!data.id;

  const prepareData = (d) => {
    if (!d.hasOwnProperty("id")) {
      return {
        id: "",
        provider: "",
        firstname: "",
        lastname: "",
        emailAddress: "",
        password: "",
      };
    }
    return d;
  };

  const dataJson = JSON.stringify(prepareData(data));
  const [values, setValues] = useState(JSON.parse(dataJson));
  const [errorMessages, setErrorMessages] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isOpenedErrorPopup, setIsOpenedErrorPopup] = useState(null);
  const [isConfirmDisconnectPopupOpen, setIsConfirmDisconnectPopupOpen] =
    useState(false);

  useEffect(() => {
    setValues(JSON.parse(dataJson));
  }, [dataJson]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = (event) => {
    event?.preventDefault();
    onClose();
  };

  const handleSubmit = async (event, isConnectOAuthAfterCreate) => {
    event?.preventDefault();

    const errors = {};

    FIELDS.forEach((f) => {
      if (!values[f.name]) {
        errors[f.name] = `${[f.label]} is required`;
      }
    });

    if (Object.keys(errors).length) {
      setErrorMessages(errors);
    } else {
      await onSave(values, !isEditMode && isConnectOAuthAfterCreate === true);
    }
  };

  const handleChangeField = (name, value) => {
    setValues({ ...values, [name]: value });
    setErrorMessages((m) => ({
      ...m,
      [name]: "",
    }));
  };

  const renderFieldCtrl = (field) => {
    const setValue = (value) => {
      handleChangeField(field.name, value);
    };

    if (field.type === "select") {
      return (
        <Select $disabled={false} $noSelected={!values[field.name]}>
          <CustomSelect
            options={[...(field.options || [])]}
            title={`Select ${field.label}...`}
            width="100%"
            selected={values[field.name] || null}
            onSelect={(val) => setValue(val || "")}
          />
        </Select>
      );
    }

    return (
      <Input
        id={field.name}
        placeholder={field.placeholder || `Enter ${field.label}...`}
        value={values[field.name] || ""}
        onFocus={(e) => {
          if (
            isEditMode &&
            field.name === "password" &&
            values[field.name] === data[field.name]
          ) {
            setValue("");
          }
        }}
        onBlur={(e) => {
          const val = e.target.value.trim();
          setValue(val);
          if (field.name === "emailAddress") {
            const d = /@([^@]+)[.][^.]+$/.exec(val)?.[1]?.toLowerCase();
            if (Object.values(AUTO_WARM_MAILBOX_PROVIDERS).includes(d)) {
              handleChangeField("provider", d);
            } else if (d === "gmail") {
              handleChangeField("provider", AUTO_WARM_MAILBOX_PROVIDERS.gsuite);
            } else if (d === "outlook") {
              handleChangeField(
                "provider",
                AUTO_WARM_MAILBOX_PROVIDERS.office365
              );
            }
          } else if (isEditMode && field.name === "password" && !val) {
            setValue(data[field.name]);
            setShowPassword(false);
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        autoComplete="off"
        type={showPassword && field.type === "password" ? "text" : field.type}
        disabled={!!field.isDisabled && field.isDisabled(values)}
        {...field.props}
      />
    );
  };

  const isAllowedOAuthConnect = () => {
    return (
      values.provider === AUTO_WARM_MAILBOX_PROVIDERS.gsuite ||
      values.provider === AUTO_WARM_MAILBOX_PROVIDERS.office365
    );
  };

  const isAllowedOAuthDisconnect = () => {
    return values.integration?.method === "oauth";
  };

  const handleConnectOAuth = async (isSuccess, message) => {
    if (isSuccess) {
      const newData = {
        type: values.provider,
        method: "oauth",
        expired: false,
      };
      await onSuccessConnect(newData);
    } else {
      setIsOpenedErrorPopup(message || "");
    }
  };

  const handleDisconnect = async () => {
    setIsConfirmDisconnectPopupOpen(false);
    await onDisconnect();
  };

  const getOAuthType = () => {
    return values.integration.type === AUTO_WARM_MAILBOX_PROVIDERS.gsuite
      ? "GSuite"
      : values.integration.type.replace(/^[a-z]/, (match) =>
          match.toUpperCase()
        );
  };

  const isGoogleProvider =
    values.provider === AUTO_WARM_MAILBOX_PROVIDERS.gsuite;

  return (
    <Container>
      {isOpenedErrorPopup !== null && (
        <SuggestionPopup
          biggerHeight={isGoogleProvider}
          title="Connection was unsuccessful"
          description={
            isOpenedErrorPopup || (
              <>
                Unfortunately, we were unable to create an integration with this
                account. You may need to contact your IT team or email us
                support@salesstream.ai for help{isGoogleProvider ? "." : ""}
                {isGoogleProvider && (
                  <>
                    <br />
                    <br />
                    Try deleting all connections to the SalesStream.ai app in
                    your Google account settings.
                  </>
                )}
              </>
            )
          }
          onConfirm={() => {
            setIsOpenedErrorPopup(null);
          }}
          confirmBtnText="Got it!"
          imageSrc="/images/sad-computer.png"
          removeCancel={true}
          styles={{
            titleStyle: {
              fontColor: "#ff0000",
            },
          }}
        />
      )}

      {isConfirmDisconnectPopupOpen && (
        <ConfirmPopup
          title="Are you sure?"
          description={`You’re about to disconnect ${getOAuthType()} integration. Are you sure you want to proceed?`}
          onConfirm={handleDisconnect}
          onCancel={() => setIsConfirmDisconnectPopupOpen(false)}
        />
      )}

      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit}>
          {FIELDS.map((field) => {
            return (
              <Inputs key={field.name}>
                <InputBlock>
                  <Label htmlFor={field.name}>{field.label}</Label>
                  <Relative>
                    {renderFieldCtrl(field)}
                    {!!errorMessages[field.name] ? (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    ) : (
                      field.type === "password" &&
                      values[field.name] !== data[field.name] && (
                        <ShowPwdIcon
                          $showPassword={showPassword}
                          onClick={toggleShowPassword}
                        />
                      )
                    )}
                  </Relative>
                  {!!errorMessages[field.name] && (
                    <ErrorMessage>{errorMessages[field.name]}</ErrorMessage>
                  )}
                </InputBlock>
              </Inputs>
            );
          })}

          {(isAllowedOAuthConnect() || isAllowedOAuthDisconnect()) && (
            <SimpleConnectBlock>
              {isAllowedOAuthConnect() && (
                <SimpleConnect
                  label={
                    values.integration?.method === "oauth"
                      ? "Reconnect OAuth"
                      : "Connect OAuth"
                  }
                  onConnect={handleConnectOAuth}
                  emailType={values.provider}
                  autoWarmMailbox={{
                    id: values.id,
                    onCreate: handleSubmit,
                    isConfirmPopupOpened: data.needToConnectOAuth,
                  }}
                  disabled={
                    !values.emailAddress.trim() ||
                    (isEditMode &&
                      values.status !== AUTO_WARM_MAILBOX_STATUSES.enabled)
                  }
                />
              )}

              {isAllowedOAuthDisconnect() && (
                <OutlineBtn
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsConfirmDisconnectPopupOpen(true);
                  }}
                >
                  {`Disconnect OAuth${
                    values.provider !== values.integration.type
                      ? ` ${getOAuthType()}`
                      : ""
                  }`}
                </OutlineBtn>
              )}
            </SimpleConnectBlock>
          )}

          <Actions>
            <ConnectBtn
              type="submit"
              label={`${isEditMode ? "Update" : "Add"} Mailbox`}
              disabled={dataJson === JSON.stringify(values)}
            />
          </Actions>
        </Form>
      </Content>
    </Container>
  );
};

export default AutoWarmMailboxPopup;

const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  max-height: 70vh;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(70vh - 80px);
  overflow: auto;
`;

const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin: 0;
  width: 90%;
  box-shadow: 0 0 10px #dfdfdf;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin: 0;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

const ErrorMessage = styled.span`
  margin-top: 5px;
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  padding-left: 10px;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const Select = styled.div`
  width: 90%;
  margin: 0;
  > div:first-child {
    ${({ $disabled }) =>
      $disabled ? "opacity: 0.65; pointer-events: none;" : ""}
    margin-bottom: 0;
    height: 40px;
    min-width: unset;
    > div:first-child {
      height: 40px;
      min-width: unset;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
        sup {
          display: none;
        }
      }
    }
  }
`;

const ShowPwdIcon = styled.div`
  position: absolute;
  top: calc((100% - 10px) / 2);
  right: 12%;
  width: 17px;
  height: 10px;
  background-image: url(images/show-pwd-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  ${({ $showPassword }) =>
    $showPassword
      ? ""
      : `
          &:after {
            content: '';
            border-top: 1px solid #A0A0A0;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            top: 2px;
            right: -9px;
            transform: rotate(-45deg);
          }
      `}
  &:hover {
    opacity: 0.85;
  }
`;

const SimpleConnectBlock = styled(Inputs)`
  flex-direction: unset;
  flex-wrap: wrap;
  gap: 20px;
  > button {
    margin: 0 !important;
    width: fit-content;
    &:not(:disabled):hover {
      opacity: 0.85;
    }
  }
`;
