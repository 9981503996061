import styled from "styled-components";

export const Content = styled.section`
  overflow: scroll;
  height: 100%;
`;

export const Title = styled.h3`
  margin-bottom: 16px;
  font-weight: 700;

  &,
  * {
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 20px;
    line-height: 30px;
  }

  span {
    font-weight: 400;
  }

  @media (min-width: 2000px) {
    font-size: 23px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;

export const Inputs = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 600;
  line-height: 19.2px;
  color: #000;
  margin-bottom: 4px;
  width: 90%;

  @media (min-width: 2000px) {
    font-size: 14px;
  }
`;

export const Relative = styled.div`
  position: relative;
`;

export const Input = styled.input`
  &,
  &:focus-visible {
    border-radius: 3px;
    border: 1px solid #d6ddeb;
    box-shadow: none;
    outline: none;
    background-color: white;
    color: black;
    height: 38px;
    width: 90%;
    padding: 9px 40px 9px 16px;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  &::placeholder {
    color: black;
    opacity: 0.6;
  }

  @media (min-width: 2000px) {
    font-size: 14px;
  }
`;

export const InputTextarea = styled.textarea`
  &,
  &:focus-visible {
    border-radius: 3px;
    border: 1px solid #d6ddeb;
    box-shadow: none;
    outline: none;
    background-color: white;
    color: black;
    min-height: 38px;
    height: 200px;
    width: 90%;
    padding: 9px 16px;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  &::placeholder {
    color: black;
    opacity: 0.6;
  }
`;

export const ShowPwdIcon = styled.img`
  position: absolute;
  top: calc((100% - 10px) / 2);
  right: calc(10% + 23px);
  height: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const ErrorIcon = styled.img`
  position: absolute;
  top: 11px;
  right: calc(10% + 23px);
  height: 17px;
`;

export const ErrorMessage = styled.span`
  margin-top: 4px;
  color: #de6363;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 10px;
  width: 90%;
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const SignInRedirectBlock = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  padding-top: 9px;
  margin-left: 33px;

  a {
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
    font-size: 10px;
    font-weight: 400;
    line-height: 14.5px;
  }
`;

export const SignInRedirectText = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;

  @media (min-width: 2000px) {
    font-size: 12px;
  }
`;

export const LinkText = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #4640de;
  cursor: pointer;

  @media (min-width: 2000px) {
    font-size: 12px;
  }
`;

export const CheckboxBlock = styled.div`
  margin-top: 4px;
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 11px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;

  @media (min-width: 2000px) {
    font-size: 13px;
  }
`;
