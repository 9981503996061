import React, { useState } from "react";

import ConnectBtn from "../../../../ConnectBtn";
import { getBaseUrl } from "../utils";

import {
  CloseIcon,
  Container,
  Content,
  Form,
  Inputs,
  InputBlock,
  Label,
  Actions,
  Relative,
  Input,
  ErrorIcon,
  ErrorMessage,
  InputTextarea,
  LinkWrapper,
  BaseUrl,
} from "./styles";

function BlogPostFormPopup({ blogPost, onSave, onClose }) {
  const [title, setTitle] = useState(blogPost.title || "");
  const [writer, setWriter] = useState(blogPost.writer || "");
  const [text, setText] = useState(blogPost.text || "");
  const [link, setLink] = useState(blogPost.link || "");
  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    } else {
      const formData = getFormData();

      onSave(formData);
    }
  };

  const getFormData = () => ({
    ...(blogPost.id ? { ...blogPost } : {}),
    title,
    writer,
    text,
    link,
  });

  const validateForm = () => {
    const errors = {};

    if (!title) errors.title = "Missed title";
    if (!writer) errors.writer = "Missed writer";
    if (!text) errors.text = "Missed text";
    if (!link) errors.link = "Missed link";

    return errors;
  };

  return (
    <Container>
      <Content>
        <CloseIcon className="close-icon" onClick={onClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="title">Title</Label>
              <Relative>
                <Input
                  id="title"
                  placeholder="Enter Title..."
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setErrorMessages((m) => ({ ...m, title: "" }));
                  }}
                  autoComplete="off"
                />
                {!!errorMessages.title && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.title && (
                <ErrorMessage>{errorMessages.title}</ErrorMessage>
              )}
            </InputBlock>
            <InputBlock>
              <Label htmlFor="writer">Writer</Label>
              <Relative>
                <Input
                  id="writer"
                  placeholder="Enter Writer..."
                  value={writer}
                  onChange={(e) => {
                    setWriter(e.target.value);
                    setErrorMessages((m) => ({ ...m, writer: "" }));
                  }}
                  autoComplete="off"
                />
                {!!errorMessages.writer && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.writer && (
                <ErrorMessage>{errorMessages.writer}</ErrorMessage>
              )}
            </InputBlock>
            <InputBlock>
              <Label htmlFor="text">Post</Label>
              <Relative>
                <InputTextarea
                  id="text"
                  placeholder="Enter your Post..."
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                    setErrorMessages((m) => ({ ...m, text: "" }));
                  }}
                />
                {!!errorMessages.text && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {!!errorMessages.text && (
                <ErrorMessage>{errorMessages.text}</ErrorMessage>
              )}
            </InputBlock>
            <InputBlock>
              <Label htmlFor="link">Link</Label>
              <Relative>
                <LinkWrapper>
                  <BaseUrl>{getBaseUrl()}/blog/</BaseUrl>
                  <Input
                    id="link"
                    placeholder="Enter Link..."
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value.trim());
                      setErrorMessages((m) => ({ ...m, link: "" }));
                    }}
                    autoComplete="off"
                  />
                  {!!errorMessages.link && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </LinkWrapper>
              </Relative>
              {!!errorMessages.link && (
                <ErrorMessage>{errorMessages.link}</ErrorMessage>
              )}
            </InputBlock>
          </Inputs>
          <Actions>
            <ConnectBtn
              type="submit"
              label={!blogPost.id ? "Create Post" : "Update Post"}
            />
          </Actions>
        </Form>
      </Content>
    </Container>
  );
}

export default BlogPostFormPopup;
