import { CRMS, INTEGRATION_TYPE } from "../utils/constants.js";
import { sendMessageToExtension } from "../utils/postToExtension.js";
import { isObjectEmpty, isSpreadsheetType } from "../utils/utils.js";
import {
  fetchCreateIntegration,
  fetchGetIntegrations,
  fetchHubspotFields,
  fetchUpdateHubspotFields,
  fetchUpdateOpenAiModels,
  fetchSendEmail,
  fetchDisconnectIntegration,
  fetchCheckEmailHealth,
} from "./queries.js";

const createIntegration = async (data) => {
  const result = await fetchCreateIntegration(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  const integrations = await fetchGetIntegrations();

  if (!integrations.message) {
    sendMessageToExtension({
      message: "save-integrations",
      data: {
        integrations,
      },
    });
  }

  return { success: true, integrations };
};

const getIntegrations = async () => {
  const result = await fetchGetIntegrations();

  if (result.message) {
    return { success: false, message: result.message };
  }

  sendMessageToExtension({
    message: "save-integrations",
    data: {
      integrations: result,
    },
  });

  return result;
};

const validateIntegrations = async (
  crmType,
  setIsLoadingIntegration,
  isRequiredEmail = true,
  isRequiredSms = false
) => {
  const isSpreadsheet = isSpreadsheetType(crmType);

  if (isSpreadsheet && !isRequiredEmail && !isRequiredSms) {
    return null;
  }

  setIsLoadingIntegration(true);
  const integrations = await getIntegrations();
  setIsLoadingIntegration(false);

  if (!integrations.integration?.crm && !isSpreadsheet) {
    return ["missing"];
  }

  const isInvalid = (integration) =>
    !integration || isObjectEmpty(integration) || integration.expired;

  const isNotEmailIntegrationApplicable =
    isRequiredEmail && isInvalid(integrations.integration?.email);

  const isNotSmsIntegrationApplicable =
    isRequiredSms && isInvalid(integrations.integration?.sms);

  if (isNotEmailIntegrationApplicable || isNotSmsIntegrationApplicable) {
    const res = [];
    if (isNotEmailIntegrationApplicable) {
      res.push(INTEGRATION_TYPE.EMAIL);
    }
    if (isNotSmsIntegrationApplicable) {
      res.push(INTEGRATION_TYPE.SMS);
    }
    return res;
  }

  if (isSpreadsheet) {
    return null;
  }

  const isNotCrmIntegrationApplicable =
    Object.values(CRMS).includes(crmType) &&
    isInvalid(integrations.integration?.crm[crmType]);

  if (isNotCrmIntegrationApplicable) {
    return [
      integrations.integration?.crm[crmType]?.expired ? "expired" : "missing",
    ];
  }

  return null;
};

const getHubspotFields = async () => {
  const result = await fetchHubspotFields();

  if (result.message) {
    return { success: false, message: result.message };
  }

  return result;
};

const updateHubspotFields = async (data) => {
  const result = await fetchUpdateHubspotFields(data);
  return result.success;
};

const updateOpenAiModels = async (data) => {
  const result = await fetchUpdateOpenAiModels(data);
  return result.success;
};

const sendEmail = async (data) => {
  return await fetchSendEmail(data);
};

const disconnectIntegration = async (data) => {
  return await fetchDisconnectIntegration(data);
};

const checkEmailHealth = async () => {
  const result = await fetchCheckEmailHealth();

  if (result.message) {
    return { success: false, message: result.message };
  }

  return { success: true, result };
};

export {
  createIntegration,
  getIntegrations,
  validateIntegrations,
  getHubspotFields,
  updateHubspotFields,
  updateOpenAiModels,
  sendEmail,
  disconnectIntegration,
  checkEmailHealth,
};
