import React from "react";

import {
  TableHead,
  TableRow,
  TableHeadData,
  TableHeadDataSort,
  SortIcon,
} from "./styles";

const COLUMN_NAME = [
  { name: "title", label: "Post Name" },
  { name: "isActive", label: "Active" },
];

const TableHeader = ({ onSort, sort, withSort }) => {
  return (
    <TableHead>
      <TableRow $bgColor="#f3f2f2">
        {COLUMN_NAME.map(({ name, label }) => (
          <TableHeadData
            key={name}
            {...(withSort
              ? {
                  $cursorPointer: true,
                  onClick: () => onSort(name),
                }
              : {})}
          >
            <TableHeadDataSort>
              {label}
              {withSort && (
                <SortIcon
                  src={`/images/sort-${
                    sort.sort === name ? `${sort.order}-` : ""
                  }icon.png`}
                  alt="sort"
                />
              )}
            </TableHeadDataSort>
          </TableHeadData>
        ))}
        <TableHeadData>
          <TableHeadDataSort>Actions</TableHeadDataSort>
        </TableHeadData>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
