import {
  fetchCampaigns,
  fetchCampaignsStatistic,
  fetchCampaignTokensCost,
  fetchCampaign,
  fetchGetTempCampaign,
  fetchPostTempCampaign,
  fetchCreateCampaign,
  fetchAddContactsToCampaign,
  fetchUpdateCampaignStatus,
  fetchPreviewTempMessage,
  fetchPreviewMessage,
  fetchUpdatePreviewMessage,
  fetchGenerateAllMessagePreviews,
  fetchStopGenerateMessagePreviews,
  fetchStrategies,
  fetchGetCampaignPreview,
  fetchPreviewsGenerationStatus,
  fetchValidateCampaignGoal,
  fetchGetFilters,
} from "./queries.js";

const getCampaigns = async (data) => {
  const result = await fetchCampaigns(data);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const getCampaignsStatistic = async () => {
  const result = await fetchCampaignsStatistic();

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const getCampaignTokensCost = async (data) => {
  const result = await fetchCampaignTokensCost(data);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const getCampaign = async (campaignId) => {
  const result = await fetchCampaign(campaignId);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const getTempCampaign = async (crm) => {
  try {
    const result = await fetchGetTempCampaign(crm);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get a Temp campaign failed" };
  }
};

const postTempCampaign = async (data) => {
  try {
    const result = await fetchPostTempCampaign(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Set a Temp campaign failed" };
  }
};

const resetTempCampaign = async (crm) => {
  return await postTempCampaign({ crm });
};

const createCampaign = async (data) => {
  const startTime = new Date();
  console.log(`Starting to create campaign`);
  const result = await fetchCreateCampaign(data);
  console.log(
    `Finished creating campaign in ${(new Date() - startTime) / 1000} secs`
  );

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

const addContactsToCampaign = async (data) => {
  const result = await fetchAddContactsToCampaign(data);

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const getStrategies = async (data) => {
  if (!data.crm) {
    return { success: false, message: "Missed CRM type" };
  }

  const result = await fetchStrategies(data);

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const generateCampaignPreview = async (data) => {
  if (!data.crm) {
    return { success: false, message: "Missed CRM type" };
  }

  const result = await fetchGetCampaignPreview(data);

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const updateCampaignStatus = async ({ campaignId, status }) => {
  const result = await fetchUpdateCampaignStatus({ campaignId, status });

  if (result.message) {
    return result;
  }

  return { success: true, result };
};

const getPreviewTempMessage = async (data) => {
  const result = await fetchPreviewTempMessage(data);

  if (result.errorMessage) {
    return result;
  }

  if (result.message && result.message.subject && result.message.text) {
    return { success: true, result };
  }

  return { success: false, errorMessage: "Invalid message data" };
};

const getPreviewMessage = async (data) => {
  const result = await fetchPreviewMessage(data);

  if (result.errorMessage) {
    return result;
  }

  if (result.inProgress) {
    return {
      success: false,
      inProgress: true,
      errorMessage: "Please wait.  The campaign is still being generated",
    };
  }

  if (result.message && result.message.subject && result.message.text) {
    return { success: true, result };
  }

  return { success: false, errorMessage: "Invalid message data" };
};

const updatePreviewMessage = async (data) => {
  const result = await fetchUpdatePreviewMessage(data);

  if (result.errorMessage) {
    return result;
  }

  return { success: true, result };
};

const generateAllMessagePreviews = async (data) => {
  const result = await fetchGenerateAllMessagePreviews(data);

  if (result.errorMessage) {
    return result;
  }

  return { success: true, result };
};

const stopGenerateMessagePreviews = async (data) => {
  const result = await fetchStopGenerateMessagePreviews(data);

  if (result.errorMessage) {
    return result;
  }

  return { success: true, result };
};

const getPreviewsGenerationStatus = async (data) => {
  const result = await fetchPreviewsGenerationStatus(data);

  if (result.errorMessage) {
    return result;
  }

  return { success: true, result };
};

const validateCampaignGoal = async (data) => {
  const result = await fetchValidateCampaignGoal(data);

  if (result.errorMessage) {
    return result;
  }

  return { success: true, result };
};

const getFilters = async (data) => {
  try {
    const result = await fetchGetFilters(data);

    if (result.message) {
      return result;
    }
    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get filters" };
  }
};

export {
  getCampaigns,
  getCampaignsStatistic,
  getCampaignTokensCost,
  getCampaign,
  getTempCampaign,
  postTempCampaign,
  resetTempCampaign,
  createCampaign,
  addContactsToCampaign,
  updateCampaignStatus,
  getPreviewTempMessage,
  getPreviewMessage,
  updatePreviewMessage,
  generateAllMessagePreviews,
  stopGenerateMessagePreviews,
  getPreviewsGenerationStatus,
  getStrategies,
  generateCampaignPreview,
  validateCampaignGoal,
  getFilters,
};
