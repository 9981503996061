import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ConnectBtn from "../../../ConnectBtn";
import { apiUrl } from "../../../../utils/config";
import { getAccessToken } from "../../../../api/axios";
import ConfirmPopup from "../../../ConfirmPopup";

const POPUP_MODES = {
  CONFIRM: "confirm",
};

function SimpleConnect({
  label,
  disabled,
  onConnect,
  emailType,
  autoWarmMailbox,
}) {
  const [openedWindow, setOpenedWindow] = useState(null);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState(
    !!autoWarmMailbox?.isConfirmPopupOpened
  );
  const [popupMode, setPopupMode] = useState(POPUP_MODES.CONFIRM);

  useEffect(() => {
    if (autoWarmMailbox?.isConfirmPopupOpened) {
      setIsConfirmPopupOpened(true);
    }
  }, [autoWarmMailbox?.isConfirmPopupOpened]);

  const closeOpenedWindow = useCallback(
    (isUnmount) => {
      if (openedWindow) {
        try {
          const { win, timer, closeWhenUnmount } = openedWindow;
          clearInterval(timer);
          if (!isUnmount || closeWhenUnmount) {
            win.close();
          }
        } catch {}
      }
    },
    [openedWindow]
  );

  useEffect(() => {
    return () => {
      closeOpenedWindow(true);
    };
  }, [closeOpenedWindow]);

  const handleClick = async (e) => {
    e?.preventDefault();

    if (autoWarmMailbox && !autoWarmMailbox.id) {
      await autoWarmMailbox.onCreate(e, true);
      return;
    }

    closeOpenedWindow();

    setIsConfirmPopupOpened(true);
  };

  const handleOauthConnect = () => {
    setIsConfirmPopupOpened(false);
    setPopupMode(POPUP_MODES.CONFIRM);

    const oauthConnectUrl = new URL(
      `${apiUrl}/integrations/oauth-connect/${emailType}`
    );
    oauthConnectUrl.searchParams.set("token", getAccessToken());
    if (autoWarmMailbox?.id) {
      oauthConnectUrl.searchParams.set("autoWarmMailboxId", autoWarmMailbox.id);
    }

    const parameters = `width=800,height=650,left=${
      (window.screen.width - 800) / 2
    },top=${(window.screen.height - 650) / 2}`;

    const win = window.open(oauthConnectUrl, "_blank", parameters);
    const timer = setInterval(() => {
      let wClosed, isSuccess, message;
      try {
        if (win.closed) {
          clearInterval(timer);
          wClosed = true;
        }
      } catch {}
      if (!wClosed) {
        try {
          if (
            win.origin &&
            ["/success", "/error"].includes(win.location.pathname)
          ) {
            isSuccess = win.location.pathname === "/success";
            const searchParams = new URLSearchParams(win.location.search);
            message = searchParams.get("message");

            clearInterval(timer);
            win.close();
            wClosed = true;
          }
        } catch {}
      }

      if (wClosed) {
        onConnect?.(isSuccess, message);
      }
    }, 1000);
    setOpenedWindow({ win, timer, closeWhenUnmount: !!onConnect });
  };

  const getPopupProps = () => {
    const props = {
      onCancel: () => {
        setIsConfirmPopupOpened(false);
        setPopupMode(POPUP_MODES.CONFIRM);
      },
    };

    return {
      ...props,
      title: "Create Email Integration",
      description:
        "Please note that you will need administrative privileges to create the integration",
      confirmLabel: "Sounds good",
      onConfirm: () => {
        handleOauthConnect();
      },
    };
  };

  return (
    <>
      <ConnectBtn
        label={label || "Click to Connect"}
        disabled={disabled}
        onClick={handleClick}
      />
      {isConfirmPopupOpened && <ConfirmPopup {...getPopupProps()} />}
    </>
  );
}

SimpleConnect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConnect: PropTypes.func,
  disabled: PropTypes.bool,
  emailType: PropTypes.string,
};

export default SimpleConnect;
