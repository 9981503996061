const exportToCSV = (headers, data, filename = "table.csv") => {
  const csvHeaders = headers.map((header) => header.label).join(",");
  const csvData = data
    .map((row) =>
      headers
        .map((header) => {
          let value = header.render?.(row);
          if (typeof value === "object") {
            value = value?.props?.dangerouslySetInnerHTML?.__html;
          }

          if (value === undefined) {
            value = row[header.name];
          }

          return `"${value !== undefined ? value : ""}"`;
        })
        .join(",")
    )
    .join("\n");

  const csvContent = `${csvHeaders}\n${csvData}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { exportToCSV };
