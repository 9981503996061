import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ALT_GOAL_NAMES, GOALS, VALIDATE_GOAL_GPT_ANSWERS } from "../constants";
import StepButton from "../../StepButton";
import ValidateGoalPopup from "./ValidateGoalPopup";
import { validateCampaignGoal } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
} from "../../../utils/constants";
import { isBullhornMarketingCampaignType } from "../utils";
import { getDataFromLocalStorage } from "../../../api/localStorage";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import {
  ButtonGroup,
  CONT_WIDTH,
  ErrorIcon,
  ErrorMessage,
  Input,
  InputContent,
  OFFSET_LEFT,
  RadioBlock,
  RadioButtonGroup,
  RadioInput,
  Relative,
  Section,
} from "../styles";
import DefineNewType from "./DefineNewType";
import SaveDraftButton from "./SaveDraftButton";

function CampaignOverview({ campaign, setCampaign, handleIncreaseStep, crm }) {
  const parentRef = useRef(null);
  const [isLinkInputOpened, setIsLinkInputOpened] = useState(false);
  const [isCustomGoalInputOpened, setIsCustomGoalInputOpened] = useState(false);
  const [selectedGoal, setGoal] = useState(campaign.goal);
  const [link, setLink] = useState("");
  const [customGoal, setCustomGoal] = useState("");
  const [title, setTitle] = useState("");
  const [linkErrorMessage, setLinkErrorMessage] = useState("");
  const [customGoalErrorMessage, setCustomGoalErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [validateGoalGPTAnswer, setValidateGoalGPTAnswer] = useState("");
  const [isValidateGoalPopupOpened, setIsValidateGoalPopupOpened] =
    useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignOverview,
    parentRef,
  };

  const handleChange = (goal) => {
    setErrorMessage("");
    setLinkErrorMessage("");
    setCustomGoalErrorMessage("");

    setGoal(goal);
    setIsCustomGoalInputOpened(false);
    setCustomGoal("");
    if (goal !== GOALS.visitLink) {
      setIsLinkInputOpened(false);
      setLink("");
      return;
    }
    setIsLinkInputOpened(true);
  };

  const handleClickDefineNewGoal = () => {
    setIsCustomGoalInputOpened(true);
    setIsLinkInputOpened(false);

    setErrorMessage("");
    setLinkErrorMessage("");
    setCustomGoalErrorMessage("");

    setGoal("");
    setLink("");
  };

  const handleCancelDefineNewGoal = () => {
    setIsCustomGoalInputOpened(false);
    setCustomGoalErrorMessage("");
  };

  const handleCreateNewGoal = (newGoal) => {
    setIsCustomGoalInputOpened(false);
    setCustomGoalErrorMessage("");

    const newGoalLc = newGoal.toLowerCase();
    const existingGoal = Object.values(GOALS).find(
      (g) =>
        g.toLowerCase() === newGoalLc ||
        ALT_GOAL_NAMES[g]?.toLowerCase() === newGoalLc
    );
    if (existingGoal) {
      handleChange(existingGoal);
      return;
    }

    setCustomGoal(newGoal);
  };

  const isLinkValidUrl = () => {
    try {
      new URL(link);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateGoal = async () => {
    setIsValidationLoading(true);
    const data = {
      crm: campaign.crmType,
      goal: customGoal,
    };
    const response = await validateCampaignGoal(data);

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      handleIncreaseStep();
      return;
    }

    if (
      Object.values(VALIDATE_GOAL_GPT_ANSWERS).includes(response.result.result)
    ) {
      if (response.result.result === VALIDATE_GOAL_GPT_ANSWERS.custom) {
        handleIncreaseStep();
        return;
      }
      setValidateGoalGPTAnswer(response.result.result);
      setIsValidateGoalPopupOpened(true);
      setIsValidationLoading(false);
    } else {
      handleIncreaseStep();
    }
  };

  const changeGoalDescription = () => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      return 'It looks like you\'d like your recipients to simply reply to your message.  Changing your campaign goal to "Any email reply" will allow Reach to better track goal completions.';
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      return 'It looks like you\'d like your recipients to click on a link.  Changing your campaign goal to "Get contact to visit a link" will allow Reach to automatically track successful clicks.';
    }

    return "";
  };

  const confirmChangeGoal = (link) => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.visitLink,
        link,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.emailReply,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }
  };

  const cancelChangeGoal = () => {
    handleIncreaseStep();
  };

  const handleClickSaveDraft = (event) => {
    event.preventDefault();

    if (title === "") {
      return;
    }

    setCampaign((campaign) => ({
      ...campaign,
      goal: selectedGoal || customGoal,
      link,
      name: title,
      isCustomGoal: !selectedGoal === "" && !!customGoal,
      asDraft: true,
    }));
  };

  const handleClickNext = (event) => {
    event.preventDefault();

    if (title === "") {
      return;
    }

    if (selectedGoal === "" && !isCustomGoalInputOpened && !customGoal) {
      setErrorMessage("Please choose a campaign goal");
      return;
    }
    if (selectedGoal === "" && (isCustomGoalInputOpened || customGoal)) {
      if (!customGoal && isCustomGoalInputOpened) {
        setCustomGoalErrorMessage("This field is required");
        return;
      }
      if (customGoal) {
        setCustomGoalErrorMessage("");
        setCampaign((campaign) => ({
          ...campaign,
          goal: customGoal,
          name: title,
          isCustomGoal: true,
        }));
        if (
          crm === CRMS.BULLHORN &&
          !isBullhornMarketingCampaignType(campaign.type)
        ) {
          validateGoal();
        } else {
          handleIncreaseStep();
        }
        return;
      }
    }
    if (selectedGoal === GOALS.visitLink) {
      if (link.length < 1) {
        setLinkErrorMessage("This field is required");
        return;
      }

      const isLinkValid = isLinkValidUrl();

      if (!isLinkValid) {
        setLinkErrorMessage("Please enter a valid link");
        return;
      }
      if (isLinkValid && link.length > 0) {
        setLinkErrorMessage("");
        setCampaign((campaign) => ({
          ...campaign,
          goal: selectedGoal,
          link,
          name: title,
          isCustomGoal: false,
        }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      goal: selectedGoal,
      name: title,
      isCustomGoal: false,
    }));
    handleIncreaseStep();
  };

  const setCampaignName = () => {
    setTitle(() => {
      const preparedCampaignName = getDataFromLocalStorage(
        PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY
      );

      if (preparedCampaignName) {
        return preparedCampaignName;
      }

      return campaign.name;
    });
  };

  useEffect(() => {
    setCampaignName();

    if (campaign.goal === GOALS.visitLink) {
      setIsLinkInputOpened(true);
      setIsCustomGoalInputOpened(false);
      setLink(campaign.link);
      return;
    }

    if (
      campaign.goal !== GOALS.emailReply &&
      campaign.goal !== GOALS.schedulePhoneCall &&
      campaign.goal !== ""
    ) {
      setIsCustomGoalInputOpened(false);
      setIsLinkInputOpened(false);
      setCustomGoal(campaign.goal);
      setGoal("");

      return;
    }

    setGoal(campaign.goal);
    setIsLinkInputOpened(false);
    setIsCustomGoalInputOpened(false);
  }, [campaign]);

  return (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <InputBlock>
          <LabelForInput htmlFor="campaign-title">
            Give your campaign a name
          </LabelForInput>
          <InputContent $width={CONT_WIDTH}>
            <Relative>
              <CampaignTitleInput
                type="text"
                id="campaign-title"
                placeholder="Campaign title"
                autoComplete="off"
                value={title}
                onInput={(event) => setTitle(event.target.value)}
                onBlur={(event) => setTitle(event.target.value.trim())}
              />
              <img
                src="/images/divider-vert.svg"
                alt="divider-vert"
                className="divider-vert"
              />
              {!title.trim() && <ErrorIcon />}
            </Relative>
            {!title.trim() && (
              <ErrorMessage>Please enter campaign title</ErrorMessage>
            )}
          </InputContent>
        </InputBlock>
        <SelectBlock>
          <LabelForSelect>What’s your goal for this campaign?</LabelForSelect>
        </SelectBlock>
        <RadioButtonGroup>
          {Object.values(GOALS).map((goal) => (
            <RadioBlock key={goal}>
              <RadioInput
                $checked={goal === selectedGoal}
                onClick={() => handleChange(goal)}
              >
                <div />
                <span>{ALT_GOAL_NAMES[goal] || goal}</span>
              </RadioInput>
              {isLinkInputOpened && goal === GOALS.visitLink && (
                <InputContent $inline={true}>
                  <Relative>
                    <Input
                      type="text"
                      placeholder="Enter the link"
                      value={link}
                      autoFocus={true}
                      onInput={(event) => {
                        setLink(event.target.value);
                        setLinkErrorMessage("");
                      }}
                      onBlur={(event) => setLink(event.target.value.trim())}
                    />
                    <img
                      src="/images/divider-vert.svg"
                      alt="divider-vert"
                      className="divider-vert"
                    />
                    {linkErrorMessage !== "" && <ErrorIcon />}
                  </Relative>
                  {linkErrorMessage !== "" && (
                    <ErrorMessage>{linkErrorMessage}</ErrorMessage>
                  )}
                </InputContent>
              )}
            </RadioBlock>
          ))}
          {!!customGoal && (
            <RadioBlock key={customGoal}>
              <RadioInput $checked={true}>
                <div />
                <span>{customGoal}</span>
              </RadioInput>
            </RadioBlock>
          )}
        </RadioButtonGroup>

        <DefineNewType
          label="Goal"
          isOpened={isCustomGoalInputOpened}
          onOpen={handleClickDefineNewGoal}
          onClose={handleCancelDefineNewGoal}
          onCreate={handleCreateNewGoal}
          onInput={() => setCustomGoalErrorMessage("")}
          errorMessage={customGoalErrorMessage}
        />

        {errorMessage !== "" && (
          <ErrorMessage $marginLeft={OFFSET_LEFT}>{errorMessage}</ErrorMessage>
        )}
      </Section>
      <ButtonGroup>
        <StepButton disabled isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={isValidationLoading}
          isNext={true}
        />
        <SaveDraftButton
          onClick={handleClickSaveDraft}
          disabled={isValidationLoading || !title.trim()}
        />
      </ButtonGroup>

      {isValidateGoalPopupOpened && (
        <ValidateGoalPopup
          title="There’s something else you can do here!"
          description={changeGoalDescription()}
          showLinkInput={
            validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link
          }
          onConfirm={confirmChangeGoal}
          onCancel={cancelChangeGoal}
        />
      )}
    </>
  );
}

export default CampaignOverview;

const InputBlock = styled.div`
  padding: 49px ${OFFSET_LEFT} 39px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 10px 10px 0 0;
`;

const SelectBlock = styled.div`
  padding: 25px ${OFFSET_LEFT} 15px;
  display: flex;
  align-items: center;
`;

const LabelForSelect = styled.label`
  margin: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
`;

const LabelForInput = styled.label`
  margin: 0 0 9px;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

const CampaignTitleInput = styled.input`
  &,
  &:focus-visible {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 #0000000d, 0 0 10px 0 #0000000d inset;
    height: auto;
    width: 100%;
    margin: 0;
    padding: 16px 31px;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    color: #000000;
    background: #ffffff;
    outline: none;
  }

  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }

  + img.divider-vert {
    position: absolute;
    left: 25px;
    top: calc((100% - 33px) / 2);
    height: 33px;
    width: 1px;
    opacity: 0.1;
  }

  &:hover {
    border-color: #bfbfbf;
  }

  &:focus-visible {
    border-color: #ababab;
    + img.divider-vert {
      opacity: 0.2;
    }
  }
`;
