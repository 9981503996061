/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import { Tooltip } from "react-tooltip";
import Loader from "../../../Loader";
import Table from "./AutoWarmTable";
import MailboxPopup from "./AutoWarmMailboxPopup";
import PlacementResultsPopup from "./PlacementResultsPopup";
import {
  fetchDeleteAutoWarmMailboxes as deleteAutoWarmMailbox,
  fetchGetAutoWarmMailboxes as getAutoWarmMailboxes,
  fetchUpdateAutoWarmMailboxes as updateAutoWarmMailbox,
  fetchRunPlacementTestForAutoWarmMailboxes as runPlacementTest,
} from "../../../../services/queries";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import ConfirmPopup from "../../../ConfirmPopup";
import {
  AUTO_WARM_MAILBOX_PROVIDERS,
  AUTO_WARM_MAILBOX_STATUSES,
  AUTO_WARM_PLACEMENT_RESULT_STATUSES,
} from "../../../../utils/constants";

const POLLING_FREQUENCY_IN_MINUTES = 0.5;

function AutoWarmWrapper() {
  const [isLoading, setIsLoading] = useState(false);
  const [mailboxes, setMailboxes] = useState([]);
  const [mailboxPopupData, setMailboxPopupData] = useState(null);
  const [mailboxConfirmDelData, setMailboxConfirmDelData] = useState(null);
  const [isShowPlacementTestConfirmPopup, setIsShowPlacementTestConfirmPopup] =
    useState(false);
  const [mailboxPlacementResPopupData, setMailboxPlacementResPopupData] =
    useState(null);

  const timeoutRef = useRef(null);

  const clearTimer = () => {
    clearTimeout(timeoutRef.current);
  };

  const mailboxesForPlacementTesting = mailboxes.filter(
    (m) => m.status === AUTO_WARM_MAILBOX_STATUSES.enabled
  );

  const fetchMailboxes = async (needToUpdatePopupData) => {
    clearTimer();

    const forceFetch = typeof needToUpdatePopupData === "boolean";
    if (forceFetch) {
      setIsLoading(true);
    }

    const result = await getAutoWarmMailboxes();

    setIsLoading(false);

    const { autoWarmMailboxes } = result.result || {};

    if (
      !autoWarmMailboxes ||
      autoWarmMailboxes.some((m) =>
        m.placementTest?.some(
          (item) => item.status === AUTO_WARM_PLACEMENT_RESULT_STATUSES.pending
        )
      )
    ) {
      timeoutRef.current = setTimeout(
        fetchMailboxes,
        POLLING_FREQUENCY_IN_MINUTES * 60 * 1000
      );
    }

    if (!result.success) {
      if (forceFetch) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message:
              "Something wrong while trying to fetch auto-warm mailboxes",
          },
        });
      }
      return;
    }

    setMailboxes(autoWarmMailboxes);

    if (needToUpdatePopupData) {
      checkPopupData(autoWarmMailboxes, needToUpdatePopupData);
    }

    return autoWarmMailboxes;
  };

  const checkPopupData = (autoWarmMailboxes, needToUpdatePopupData) => {
    if (mailboxPopupData?.id) {
      const openedMailbox = autoWarmMailboxes.find(
        (m) => m.id === mailboxPopupData.id
      );
      if (!openedMailbox) {
        setMailboxPopupData(null);
      } else if (needToUpdatePopupData === true) {
        setMailboxPopupData(openedMailbox);
      }
    }

    if (mailboxPlacementResPopupData?.id) {
      const openedMailbox = autoWarmMailboxes.find(
        (m) => m.id === mailboxPlacementResPopupData.id
      );
      if (!openedMailbox?.placementTest?.length) {
        setMailboxPlacementResPopupData(null);
      } else {
        setMailboxPlacementResPopupData(openedMailbox);
      }
    }
  };

  useEffect(() => {
    checkPopupData(mailboxes, false);
  }, [mailboxes]);

  useEffect(() => {
    fetchMailboxes(false);

    return () => {
      clearTimer();
    };
  }, []);

  const handleOpenMailboxPopup = (mailbox = {}) => {
    setMailboxPopupData(mailbox);
  };

  const handleCloseMailboxPopup = () => {
    setMailboxPopupData(null);
  };

  const handleConfirmDeleteMailbox = (mailbox) => {
    setMailboxConfirmDelData(mailbox);
  };

  const handleDeleteMailbox = async () => {
    const { id } = mailboxConfirmDelData;

    setMailboxConfirmDelData(null);
    setIsLoading(true);

    const result = await deleteAutoWarmMailbox({ id: [id] });

    setIsLoading(false);

    if (!result.success) {
      console.log("handleDeleteMailbox", result.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            "Something went wrong when trying to delete mailbox",
        },
      });
      return;
    }

    await fetchMailboxes(false);
  };

  const handleSaveMailbox = async (data, isConnectOAuthAfterCreate) => {
    if (!isConnectOAuthAfterCreate) {
      setMailboxPopupData(null);
    }
    setIsLoading(true);

    const result = await updateAutoWarmMailbox(data);

    setIsLoading(false);

    if (!result.success) {
      console.log("handleSaveMailbox", result.message);
      setMailboxPopupData(null);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            `Something went wrong when trying to ${
              data.id ? "update" : "create"
            } mailbox`,
        },
      });
      return;
    }

    if (isConnectOAuthAfterCreate) {
      const { autoWarmMailbox } = result.result;
      setMailboxPopupData({ ...autoWarmMailbox, needToConnectOAuth: true });
    }

    await fetchMailboxes(false);
  };

  const handleSuccessConnectIntegration = async (integration) => {
    const newData = {
      ...mailboxPopupData,
      integration,
    };
    delete newData.needToConnectOAuth;
    setMailboxPopupData(newData);
    await fetchMailboxes(true);
  };

  const handleDisconnectIntegration = async () => {
    setIsLoading(true);

    const result = await updateAutoWarmMailbox({
      id: mailboxPopupData.id,
      needToDisconnectIntegration: true,
    });

    setIsLoading(false);

    if (!result.success) {
      console.log("handleDisconnectIntegration", result.message);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            /* result.message || */
            `Something went wrong when trying to disconnect integration`,
        },
      });
      return;
    }

    const isOffice365 =
      mailboxPopupData.integration.type ===
      AUTO_WARM_MAILBOX_PROVIDERS.office365;
    sendMessageToExtension({
      message: "show-success-message",
      data: {
        message: `Please revoke the "SalesStream.ai Reach" app's access to information in your ${
          isOffice365 ? "Office 365 " : "Google"
        } Account settings.`,
        title: "",
      },
    });
    setTimeout(() => {
      window.open(
        isOffice365
          ? "https://account.live.com/consent/Manage"
          : "https://myaccount.google.com/connections",
        "_blank"
      );
    }, 1500);

    setMailboxPopupData({ ...mailboxPopupData, integration: {} });

    await fetchMailboxes(true);
  };

  const handleRunPlacementTest = async () => {
    setIsShowPlacementTestConfirmPopup(false);
    setIsLoading(true);

    const result = await runPlacementTest();

    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            (result.message?.startsWith("[] ")
              ? result.message.substring(3)
              : null) ||
            "Something went wrong when trying to run a placement test",
        },
      });
      return;
    }

    await fetchMailboxes(false);
  };

  const handleViewPlacementResults = async (mailbox) => {
    const updMailboxes = await fetchMailboxes(false);
    const updMailbox = updMailboxes?.find((m) => m.id === mailbox.id);
    if (updMailbox) {
      setMailboxPlacementResPopupData(updMailbox);
    }
  };

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Loader parentSize={true} padding="0" />
        </LoaderWrapper>
      )}

      <Content>
        <Title>AutoWarm</Title>

        <BtnToolbar>
          <AddBtn
            onClick={() => handleOpenMailboxPopup()}
            disabled={isLoading || !!mailboxPopupData}
          >
            Add mailbox
          </AddBtn>
          <AddBtn
            onClick={() => setIsShowPlacementTestConfirmPopup(true)}
            disabled={
              isLoading ||
              !!mailboxPopupData ||
              mailboxesForPlacementTesting.length <= 1
            }
            data-tooltip-id={
              mailboxesForPlacementTesting.length <= 1
                ? "no-mailboxes-for-pt"
                : undefined
            }
            data-tooltip-content={
              !mailboxesForPlacementTesting.length
                ? "No enabled mailboxes"
                : mailboxesForPlacementTesting.length === 1
                ? "No recipient mailboxes"
                : undefined
            }
          >
            Run placement test
          </AddBtn>
          {mailboxesForPlacementTesting.length <= 1 && (
            <Tooltip
              id="no-mailboxes-for-pt"
              className="custom-tooltip"
              place="right"
            />
          )}
        </BtnToolbar>

        {!!mailboxPopupData && (
          <MailboxPopup
            data={mailboxPopupData}
            onClose={handleCloseMailboxPopup}
            onSave={handleSaveMailbox}
            onDisconnect={handleDisconnectIntegration}
            onSuccessConnect={handleSuccessConnectIntegration}
          />
        )}

        {!!mailboxConfirmDelData && (
          <ConfirmPopup
            title="Delete mailbox"
            description={
              <>
                Are you sure you want to delete{" "}
                <strong>{mailboxConfirmDelData.emailAddress}</strong> mailbox?
              </>
            }
            onCancel={() => setMailboxConfirmDelData(null)}
            onConfirm={handleDeleteMailbox}
            confirmLabel="Delete"
          />
        )}

        {isShowPlacementTestConfirmPopup && (
          <ConfirmPopup
            title="Run placement test"
            description="Are you sure you’d like to run a placement test?"
            onCancel={() => setIsShowPlacementTestConfirmPopup(false)}
            onConfirm={handleRunPlacementTest}
          />
        )}

        {!!mailboxPlacementResPopupData && (
          <PlacementResultsPopup
            data={mailboxPlacementResPopupData.placementTest}
            onClose={() => setMailboxPlacementResPopupData(null)}
          />
        )}

        <Table
          data={mailboxes}
          onSave={handleSaveMailbox}
          onDelete={handleConfirmDeleteMailbox}
          onOpenPopup={handleOpenMailboxPopup}
          onViewPlacementResults={handleViewPlacementResults}
        />
      </Content>
    </Container>
  );
}

export default AutoWarmWrapper;

const Container = styled.div`
  padding: 0 20px 0 0;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: #ffffff47; */
  z-index: 100;
`;

const BtnToolbar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;

const AddBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  border: none;
  font-weight: 800;
  margin-bottom: 20px;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
  }
  &:not([disabled]):hover {
    opacity: 0.85;
  }
`;
