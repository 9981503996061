/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { styled } from "styled-components";
import {
  SMS_TIMING_OPTIONS,
  MESSAGE_TYPES,
  BEGIN_OPTION_DATE_SELECTION,
  AI_MODEL,
  AI_MODEL_FIELDS_IN_COMPANY,
  MESSAGE_TYPES_OPTIONS,
  ATTEMPTS_OPTIONS,
  DURATION_OPTIONS,
  DURATION_OPTIONS_FOR_ADMIN,
  TONE_OPTIONS,
  BEGIN_OPTIONS,
  GOALS,
} from "../../constants";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import { getFilters } from "../../../../services/campaigns";
import StepButton from "../../../StepButton";
import {
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
  USER_ROLES,
} from "../../../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  saveDataToLocalStorage,
} from "../../../../api/localStorage";
import SuggestionPopup from "../../../SuggestionPopup";
import FirstTimeCampaignOnboarding from "../../../FirstTimeCampaignOnboarding";
import { Tooltip } from "react-tooltip";
import AdvancedSelect from "../../../AdvancedSelect";
import DatePicker from "react-datepicker";
import {
  GridItem,
  Label,
  CheckboxBlock,
  CheckboxLabel,
  SequenceTemplateField,
  DateSelect,
  InputTextarea,
} from "./styles";
import {
  buildSequenceTemplateStepName,
  isSpreadsheetType,
} from "../../../../utils/utils";
import {
  ButtonGroup,
  ErrorMessage,
  OFFSET_LEFT /* , Section */,
} from "../../styles";
import SaveDraftButton from "../SaveDraftButton";
import { hasEmailStepInSequence, hasSmsStepInSequence } from "../../utils";

const OPTIONS_CONT_PADDING = {
  top: 39,
  bottom: 17,
};

const SELECT_DROPDOWN_HEADING_CLASS_NAME = "dropdown-heading";
const SELECT_DROPDOWN_CONTENT_CLASS_NAME = "dropdown-content";

function ConfigureOptions({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  onCloseWizard,
  isEmailIntegration,
  isSmsIntegration,
  isEstimatedTokenLoading,
}) {
  const ref = useRef(null);
  const optionsRef = useRef(null);
  const mutationObserverRef = useRef(null);
  const viewMoreRef = useRef(null);

  const userInfo = getUserFromLocalStorage();
  const isAdminUser = userInfo?.role === USER_ROLES.ADMIN;

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.configureOptions,
    parentRef: ref,
  };

  const [errorSequenceTemplate, setErrorSequenceTemplate] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("");
  const [errorMessageAttempts, setErrorMessageAttempts] = useState("");
  const [errorMessageBegin, setErrorMessageBegin] = useState("");
  const [errorMessageBeginDate, setErrorMessageBeginDate] = useState("");
  const [errorMessageDuration, setErrorMessageDuration] = useState("");
  const [errorMessageTone, setErrorMessageTone] = useState("");
  const [errorMessageSmsTiming, setErrorMessageSmsTiming] = useState("");
  const [isShowViewMore, setIsShowViewMore] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [messageType, setMessageType] = useState(
    !campaign.useSMS
      ? MESSAGE_TYPES.email
      : campaign.smsTiming === 1 && campaign.attempts === 1
      ? MESSAGE_TYPES.sms
      : MESSAGE_TYPES.emailAndSms
  );

  const [instructions, setInstructions] = useState(
    campaign.additionalInstructions || ""
  );

  const [sampleEmail, setSampleEmail] = useState(campaign.sampleEmail || "");

  const [
    isUseContactNotesInCommunications,
    setIsUseContactNotesInCommunications,
  ] = useState(false);

  const [sequenceTemplateOptions, setSequenceTemplateOptions] = useState(null);
  const [
    isFirstTimeVisitConfigureOptionsStep,
    setIsFirstTimeVisitConfigureOptionsStep,
  ] = useState(null);

  useEffect(() => {
    if (campaign.isFullSequenceCampaign && !sequenceTemplateOptions) {
      const getOptions = async () => {
        const filtersData = await getFilters({
          filter: ["sequenceTemplates"],
        });
        if (filtersData.success) {
          const options = (filtersData.result?.sequenceTemplates || []).map(
            (o) => ({
              value: o.id,
              label: o.name,
              steps: o.steps,
            })
          );
          setSequenceTemplateOptions(options);

          setCampaign((campaign) => ({
            ...campaign,
            sequenceTemplates: options,
          }));

          if (
            campaign.sequenceTemplate &&
            !options.some((o) => o.value === campaign.sequenceTemplate)
          ) {
            handleSelectSequenceTemplate(null);
          }
        } else {
          setSequenceTemplateOptions([]);
          handleSelectSequenceTemplate(null);
        }
      };
      getOptions();
    }
  }, [campaign.isFullSequenceCampaign]);

  const handleClickNext = (e) => {
    e.preventDefault();

    if (campaign.begin === BEGIN_OPTION_DATE_SELECTION && !campaign.beginDate) {
      setErrorMessageBeginDate('Missed "Begin date"');
      return;
    }

    if (campaign.isFullSequenceCampaign && !campaign.sequenceTemplate) {
      setErrorSequenceTemplate('Missed "Sequence template"');
      return;
    }

    handleIncreaseStep(e);
    saveDataToLocalStorage(
      IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
      false
    );
  };

  const handleClickPrevious = (e) => {
    e.preventDefault();
    handleDecreaseStep(e);
    saveDataToLocalStorage(
      IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
      false
    );
  };

  const handleClickSaveDraft = (e) => {
    e.preventDefault();
    onCloseWizard(e);
    saveDataToLocalStorage(
      IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
      false
    );
  };

  const getFilteredSMSTimingOptions = useCallback(() => {
    const SMSTimingOptions = SMS_TIMING_OPTIONS.filter(
      (option) => option.value <= campaign.attempts
    );
    return SMSTimingOptions;
  }, [campaign.attempts]);

  useEffect(() => {
    const observerCallback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type !== "childList" || !mutation.addedNodes.length)
          continue;
        const node = mutation.addedNodes[0];
        if (
          node.nodeType !== Node.ELEMENT_NODE ||
          !node.classList.contains(SELECT_DROPDOWN_CONTENT_CLASS_NAME)
        )
          continue;

        handleDropdownContent(node, mutationObserverRef.current);
      }
    };

    mutationObserverRef.current = new MutationObserver(observerCallback);

    if (ref.current) {
      mutationObserverRef.current.observe(ref.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      mutationObserverRef.current.disconnect();
    };
  }, []);

  const handleDropdownContent = async (node, observer) => {
    const viewMoreRect = getElementRect(viewMoreRef);
    if (!viewMoreRect) {
      return;
    }

    const nodeRect = node.getBoundingClientRect();

    if (!isViewMore && isRectOverlapping(nodeRect, viewMoreRect)) {
      setIsViewMore(true);
      const dropdownContainer = node.parentNode;
      const dropdownHeading = dropdownContainer.querySelector(
        `.${SELECT_DROPDOWN_HEADING_CLASS_NAME}`
      );

      // This is hack. We hide 'dropdown-content' container by first click, because we get this container twice - after click and after render(when state is changed).
      node.style.display = `none`;
      await new Promise((resolve) => setTimeout(resolve, 100));
      //

      dropdownHeading.click();

      observer.disconnect();
    }
  };

  const getElementRect = (ref) => {
    const element = ref.current;
    if (!element) {
      return null;
    }
    return element.getBoundingClientRect();
  };

  const isRectOverlapping = (rect1, rect2) => {
    return (
      rect1.top < rect2.bottom &&
      rect1.bottom > rect2.top &&
      rect1.left < rect2.right &&
      rect1.right > rect2.left
    );
  };

  useEffect(() => {
    const isFirstTimeVisitConfigureOptionsStep = getDataFromLocalStorage(
      IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY
    );

    if (isFirstTimeVisitConfigureOptionsStep === null) {
      setIsFirstTimeVisitConfigureOptionsStep(true);
      saveDataToLocalStorage(
        IS_FIRST_TIME_VISIT_CONFIGURE_OPTIONS_STEP_LOCAL_STORAGE_KEY,
        true
      );
    } else {
      setIsFirstTimeVisitConfigureOptionsStep(false);
    }
  }, []);

  const handleSelectChange = (value, fieldName) => {
    setErrorMessageAttempts("");
    setErrorMessageBegin("");
    setErrorMessageDuration("");
    setErrorMessageTone("");
    setErrorMessageSmsTiming("");
    if (fieldName === "beginDate") {
      setErrorMessageBeginDate("");
      setCampaign((campaign) => ({ ...campaign, [fieldName]: value }));
      return;
    }
    if (fieldName === "begin" && value !== BEGIN_OPTION_DATE_SELECTION) {
      setErrorMessageBeginDate("");
      setCampaign((campaign) => ({
        ...campaign,
        [fieldName]: value,
        beginDate: "",
      }));
      return;
    }
    setCampaign((campaign) => ({ ...campaign, [fieldName]: value }));
  };

  const changeMessageType = (value) => {
    setMessageType(value);
    if (
      (value === MESSAGE_TYPES.emailAndSms || value === MESSAGE_TYPES.sms) &&
      !isSmsIntegration
    ) {
      setErrorMessageType("You do not have an active SMS integration");
      return;
    }
    if (
      value === MESSAGE_TYPES.email ||
      ((value === MESSAGE_TYPES.emailAndSms || value === MESSAGE_TYPES.sms) &&
        isSmsIntegration)
    ) {
      setErrorMessageType("");
      setCampaign((campaign) => ({
        ...campaign,
        useSMS: value !== MESSAGE_TYPES.email,
        smsTiming: value === MESSAGE_TYPES.sms ? 1 : campaign.smsTiming,
        attempts: value === MESSAGE_TYPES.sms ? 1 : campaign.attempts,
      }));
    }
  };

  const handleChangeTextField = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleChangeAddInstr = handleChangeTextField(setInstructions);

  const handleChangeSampleEmail = handleChangeTextField(setSampleEmail);

  const handleBlurTextField = (setter, name) => (e) => {
    const val = e.target.value.trim();
    setter(val);
    handleSelectChange(val, name);
  };

  const handleBlurAddInstr = handleBlurTextField(
    setInstructions,
    "additionalInstructions"
  );

  const handleBlurSampleEmail = handleBlurTextField(
    setSampleEmail,
    "sampleEmail"
  );

  const handleMouseOutTextField = (handleCallback) => (e) => {
    try {
      if (e.target === document.activeElement) {
        handleCallback(e);
      }
    } catch {}
  };

  const handleMouseOutAddInstr = handleMouseOutTextField(handleBlurAddInstr);

  const handleMouseOutSampleEmail = handleMouseOutTextField(
    handleBlurSampleEmail
  );

  const handleClickField = (e) => {
    const viewMoreRect = getElementRect(viewMoreRef);
    if (!viewMoreRect) {
      return;
    }

    const targetRect = e.target.getBoundingClientRect();

    console.log(targetRect);

    if (!isViewMore && isRectOverlapping(targetRect, viewMoreRect)) {
      setIsViewMore(true);
    }
  };

  const companyInfo = getObjectFromLocalStorage("companyInfo");
  const aiModelOptions = Object.values(AI_MODEL)
    .filter((value) => campaign.aiModel || value !== AI_MODEL.DEFAULT)
    .map((value) => ({
      value,
      label: value,
      disabled:
        value !== AI_MODEL.DEFAULT &&
        !companyInfo?.company?.aiModel?.[AI_MODEL_FIELDS_IN_COMPANY[value]] &&
        !companyInfo?.company?.[AI_MODEL_FIELDS_IN_COMPANY[value]],
    }));
  const isDisabledAiModelField = !aiModelOptions.some((o) => !o.disabled);

  const handleChangeContactNotesInCom = (event) => {
    const useContactNotesInCommunications = event.target.checked;
    if (useContactNotesInCommunications) {
      setIsUseContactNotesInCommunications(true);
    } else {
      handleSelectChange(
        useContactNotesInCommunications,
        "useContactNotesInCommunications"
      );
    }
  };

  const handleChangeFullSequence = ({
    target: { checked: isFullSequenceCampaign },
  }) => {
    setCampaign((campaign) => ({
      ...campaign,
      isFullSequenceCampaign,
      sequenceTemplate: null,
    }));
    setErrorSequenceTemplate("");
  };

  const handleSelectSequenceTemplate = (sequenceTemplate) => {
    setCampaign((campaign) => ({ ...campaign, sequenceTemplate }));

    const hasSmsStep = hasSmsStepInSequence(
      sequenceTemplate,
      sequenceTemplateOptions
    );
    const hasEmailStep = hasEmailStepInSequence(
      sequenceTemplate,
      sequenceTemplateOptions
    );

    const noIntegrations = [];
    if (hasEmailStep && !isEmailIntegration) {
      noIntegrations.push("Email");
    }
    if (hasSmsStep && !isSmsIntegration) {
      noIntegrations.push("SMS");
    }

    let errMsg = "";
    if (noIntegrations.length) {
      errMsg = `You do not have an active ${noIntegrations.join(
        " and "
      )} integration${noIntegrations.length > 1 ? "s" : ""}`;
    }
    setErrorSequenceTemplate(errMsg);
  };

  const fields = [
    {
      label: "Full Sequence Campaign",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="isFullSequenceCampaign"
              type="checkbox"
              checked={campaign.isFullSequenceCampaign}
              value={campaign.isFullSequenceCampaign}
              onChange={handleChangeFullSequence}
            />
            <label
              htmlFor="isFullSequenceCampaign"
              className="toggle"
              onClick={handleClickField}
            ></label>
            <CheckboxLabel $checked={campaign.isFullSequenceCampaign}>
              {campaign.isFullSequenceCampaign ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isBoolean: true,
      isAllowed: () => {
        return isAdminUser;
      },
    },
    {
      label: "Sequence template",
      get: () => {
        return (
          <>
            <SequenceTemplateField>
              <AdvancedSelect
                disabled={!sequenceTemplateOptions}
                options={sequenceTemplateOptions || []}
                label="Sequence template"
                value={campaign.sequenceTemplate}
                onSelect={handleSelectSequenceTemplate}
              />
              <div
                style={
                  campaign.sequenceTemplate ? null : { visibility: "hidden" }
                }
                data-tooltip-id={
                  campaign.sequenceTemplate ? "sequence-template-tooltip" : null
                }
                data-tooltip-content={
                  campaign.sequenceTemplate
                    ? sequenceTemplateOptions
                        ?.find((o) => o.value === campaign.sequenceTemplate)
                        ?.steps?.map((step) =>
                          buildSequenceTemplateStepName(step, true)
                        )
                        .join("\n")
                    : null
                }
              >
                <img
                  src="/images/input-warning-icon.svg"
                  alt="info"
                  width="18"
                />
              </div>
              <Tooltip
                id="sequence-template-tooltip"
                className="custom-tooltip custom-tooltip-poppins ws-pre-wrap"
                place="right"
              />
            </SequenceTemplateField>
            {errorSequenceTemplate !== "" && (
              <ErrorMessage>{errorSequenceTemplate}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return campaign.isFullSequenceCampaign;
      },
    },
    {
      label: "Message types",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={MESSAGE_TYPES_OPTIONS}
              label="Message types"
              value={messageType}
              onSelect={changeMessageType}
            />
            {errorMessageType !== "" && (
              <ErrorMessage>{errorMessageType}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      label: "# of Follow-ups",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={ATTEMPTS_OPTIONS}
              label="Follow-ups"
              value={campaign.attempts - 1}
              onSelect={(value) => handleSelectChange(value + 1, "attempts")}
            />
            {errorMessageAttempts !== "" && (
              <ErrorMessage>{errorMessageAttempts}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign && messageType !== MESSAGE_TYPES.sms
        );
      },
    },
    {
      label: "Campaign Duration",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={
                isAdminUser
                  ? [...DURATION_OPTIONS, ...DURATION_OPTIONS_FOR_ADMIN]
                  : DURATION_OPTIONS
              }
              label="Duration"
              value={campaign.duration}
              onSelect={(value) => handleSelectChange(value, "duration")}
            />
            {errorMessageDuration !== "" && (
              <ErrorMessage>{errorMessageDuration}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      label: "Tone",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={TONE_OPTIONS}
              label="Tone"
              value={campaign.tone}
              onSelect={(value) => handleSelectChange(value, "tone")}
            />
            {errorMessageTone !== "" && (
              <ErrorMessage>{errorMessageTone}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return true;
      },
    },

    {
      label: "Sample email",
      get: () => {
        return (
          <InputTextarea
            id="sampleEmail"
            placeholder="Enter a sample email here"
            value={sampleEmail}
            onChange={handleChangeSampleEmail}
            onBlur={handleBlurSampleEmail}
            onMouseOut={handleMouseOutSampleEmail}
            autoComplete="off"
            onClick={handleClickField}
          />
        );
      },
      containerProps: { $width: "40%" },
      isAllowed: () => {
        return true;
      },
    },

    {
      label: "AI Model",
      get: () => {
        return (
          <>
            <div
              data-tooltip-id={
                isDisabledAiModelField ? "no-ai-model-tooltip" : null
              }
              data-tooltip-content={
                isAdminUser
                  ? `Please add ${Object.values(AI_MODEL)
                      .filter((v) => v !== AI_MODEL.DEFAULT)
                      .join("/")} models for the company in the admin settings`
                  : `To enable additional models, please contact support at help@salesstream.ai`
              }
            >
              <AdvancedSelect
                disabled={isDisabledAiModelField}
                options={aiModelOptions}
                placeholder={AI_MODEL.DEFAULT}
                label="AI Model"
                value={campaign.aiModel}
                onSelect={(value) =>
                  handleSelectChange(
                    value && value !== AI_MODEL.DEFAULT ? value : "",
                    "aiModel"
                  )
                }
              />
            </div>
            <Tooltip
              id="no-ai-model-tooltip"
              className="custom-tooltip custom-tooltip-poppins custom-tooltip_custom-width"
              place="right"
            />
          </>
        );
      },
      isAllowed: () => {
        return true;
      },
    },
    {
      label: "Begin",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={BEGIN_OPTIONS}
              label="Begin"
              value={campaign.begin}
              onSelect={(value) => handleSelectChange(value, "begin")}
            />
            {errorMessageBegin !== "" && (
              <ErrorMessage>{errorMessageBegin}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return !campaign.isFullSequenceCampaign;
      },
    },
    {
      label: "Begin date",
      get: () => {
        return (
          <>
            <DateSelect>
              <DatePicker
                placeholderText="MM/dd/yyyy"
                dateFormat="MM/dd/yyyy"
                selected={
                  campaign.beginDate ? new Date(campaign.beginDate) : null
                }
                onChange={(date) =>
                  handleSelectChange(date?.toISOString() || "", "beginDate")
                }
                minDate={new Date()}
                isClearable={true}
                clearButtonTitle="Clear"
                todayButton="Today"
              />
            </DateSelect>
            {errorMessageBeginDate !== "" && (
              <ErrorMessage>{errorMessageBeginDate}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          campaign.begin === BEGIN_OPTION_DATE_SELECTION
        );
      },
    },
    {
      label: "SMS Timing",
      get: () => {
        return (
          <>
            <AdvancedSelect
              options={getFilteredSMSTimingOptions()}
              label="SMS Timing"
              value={campaign.smsTiming}
              onSelect={(value) => handleSelectChange(value, "smsTiming")}
            />
            {errorMessageSmsTiming !== "" && (
              <ErrorMessage>{errorMessageSmsTiming}</ErrorMessage>
            )}
          </>
        );
      },
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          campaign.useSMS &&
          messageType !== MESSAGE_TYPES.sms
        );
      },
    },
    {
      label: "Send follow-ups as replies",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="followUpsAsRepliesCheckbox"
              type="checkbox"
              checked={campaign.isFollowUpsAsReplies}
              value={campaign.isFollowUpsAsReplies}
              onChange={(event) =>
                handleSelectChange(event.target.checked, "isFollowUpsAsReplies")
              }
            />
            <label
              htmlFor="followUpsAsRepliesCheckbox"
              className="toggle"
              onClick={handleClickField}
            ></label>
            <CheckboxLabel $checked={campaign.isFollowUpsAsReplies}>
              {campaign.isFollowUpsAsReplies ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isBoolean: true,
      isAllowed: () => {
        return true;
      },
    },
    {
      label: "Use contact notes in communications",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="useContactNotesInCommunicationsCheckbox"
              type="checkbox"
              checked={campaign.useContactNotesInCommunications}
              value={campaign.useContactNotesInCommunications}
              onChange={handleChangeContactNotesInCom}
            />
            <label
              htmlFor="useContactNotesInCommunicationsCheckbox"
              className="toggle"
              onClick={handleClickField}
            ></label>
            <CheckboxLabel $checked={campaign.useContactNotesInCommunications}>
              {campaign.useContactNotesInCommunications ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isBoolean: true,
      isAllowed: () => {
        return !isSpreadsheetType(campaign.crmType);
      },
    },
    {
      label: "Auto Reply",
      get: () => {
        return (
          <CheckboxBlock>
            <input
              id="autoReplyCheckbox"
              type="checkbox"
              checked={campaign.autoReply}
              value={campaign.autoReply}
              onChange={(event) =>
                handleSelectChange(event.target.checked, "autoReply")
              }
            />
            <label
              htmlFor="autoReplyCheckbox"
              className="toggle"
              onClick={handleClickField}
            ></label>
            <CheckboxLabel $checked={campaign.autoReply}>
              {campaign.autoReply ? "On" : "Off"}
            </CheckboxLabel>
          </CheckboxBlock>
        );
      },
      isBoolean: true,
      isAllowed: () => {
        return (
          !campaign.isFullSequenceCampaign &&
          isAdminUser &&
          !!isEmailIntegration &&
          campaign.goal !== GOALS.emailReply
        );
      },
    },
    {
      label: "Additional Instructions",
      get: () => {
        return (
          <InputTextarea
            id="instructions"
            placeholder="Make the messages around 50 words long…"
            value={instructions}
            onChange={handleChangeAddInstr}
            onBlur={handleBlurAddInstr}
            onMouseOut={handleMouseOutAddInstr}
            autoComplete="off"
            onClick={handleClickField}
          />
        );
      },
      containerProps: { $width: "40%" },
      isAllowed: () => {
        return true;
      },
    },
  ].filter((f) => f.isAllowed());

  useEffect(() => {
    const handleResize = () => {
      const height = optionsRef?.current?.getBoundingClientRect()?.height;
      const parentHeight =
        optionsRef?.current?.parentElement?.getBoundingClientRect()?.height -
        OPTIONS_CONT_PADDING.top -
        OPTIONS_CONT_PADDING.bottom;
      setIsShowViewMore(height > parentHeight);
    };

    const currentRef = optionsRef?.current;
    const resizeObserver = new ResizeObserver(handleResize);

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [optionsRef]);

  const isDisabledAnyButton = isEstimatedTokenLoading;

  const isDisabledPrimaryButton =
    isDisabledAnyButton ||
    campaign.candidatesLength === 0 ||
    errorSequenceTemplate !== "" ||
    errorMessageBeginDate !== "" ||
    errorMessageType !== "";

  return (
    <>
      <Section ref={ref}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        {isUseContactNotesInCommunications &&
          !campaign.useContactNotesInCommunications && (
            <SuggestionPopup
              title="We thought you should know!"
              description="Using contact notes in campaigns may greatly increase AI Credit usage."
              onConfirm={() => {
                setIsUseContactNotesInCommunications(false);
                handleSelectChange(
                  !campaign.useContactNotesInCommunications,
                  "useContactNotesInCommunications"
                );
              }}
              onCancel={() => setIsUseContactNotesInCommunications(false)}
              confirmBtnText="Got it!"
              cancelBtnText="Maybe later"
              styles={{
                titleStyle: {
                  fontColor: "#ff0000",
                },
              }}
            />
          )}
        <OptionsContainer>
          <OptionsItems ref={optionsRef} $isViewMore={isViewMore}>
            {fields.map((field) => (
              <GridItem key={field.label} {...field.containerProps}>
                <Label $isBoolean={field.isBoolean}>{field.label}:</Label>
                {field.get()}
              </GridItem>
            ))}
          </OptionsItems>
          {isShowViewMore && !isViewMore && (
            <ViewMore
              ref={viewMoreRef}
              $isFirstTimeVisitConfigureOptionsStep={
                isFirstTimeVisitConfigureOptionsStep
              }
            >
              <div onClick={() => setIsViewMore(true)}>
                Click to view more options
              </div>
              <hr />
            </ViewMore>
          )}
        </OptionsContainer>
      </Section>
      <ButtonGroup $paddingTop="16px">
        <StepButton
          onClick={handleClickPrevious}
          isNext={false}
          disabled={isDisabledAnyButton}
        />
        <StepButton
          onClick={handleClickNext}
          isNext={true}
          disabled={isDisabledPrimaryButton}
        />
        <SaveDraftButton
          onClick={handleClickSaveDraft}
          disabled={isDisabledAnyButton}
        />
      </ButtonGroup>
    </>
  );
}

export default ConfigureOptions;

const DROPDOWN_OFFSET_LEFT = "3px";

const Section = styled.div`
  padding-bottom: 12px;
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  flex-grow: 1;
  overflow: hidden;
`;

const OptionsContainer = styled.div`
  position: relative;
  padding: ${OPTIONS_CONT_PADDING.top}px
    calc(${OFFSET_LEFT} - ${DROPDOWN_OFFSET_LEFT})
    ${OPTIONS_CONT_PADDING.bottom}px;
  height: 100%;
  border-radius: 10px 10px 0 0;
`;

const OptionsItems = styled.div`
  padding-left: ${DROPDOWN_OFFSET_LEFT};
  padding-bottom: 20px;
  ${({ $isViewMore }) =>
    $isViewMore
      ? `
          height: 100%;
          overflow: auto;
        `
      : ""}
`;

const ViewMore = styled.div`
  position: absolute;
  left: 17px;
  bottom: -62px;
  width: calc(100% - 17px - 31px);
  height: 19.3vh;
  min-height: 100px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  row-gap: 11px;
  background: linear-gradient(
    360deg,
    #ffffff 41.78%,
    rgba(255, 255, 255, 0.7) 100%
  );

  div {
    padding: ${({ $isFirstTimeVisitConfigureOptionsStep }) =>
      $isFirstTimeVisitConfigureOptionsStep
        ? "4px 6px 1px 20px"
        : "4px 0 1px 16px"};
    background-image: url(/images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 7px auto;
    background-position: ${({ $isFirstTimeVisitConfigureOptionsStep }) =>
      $isFirstTimeVisitConfigureOptionsStep ? "4px center" : "0 center"};
    color: #a4a4a4;
    font-family: "Poppins", sans-serif;
    font-size: 8px;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;

    ${({ $isFirstTimeVisitConfigureOptionsStep }) =>
      $isFirstTimeVisitConfigureOptionsStep &&
      `
        animation: pulse 2s infinite;

        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }
    `}
  }

  hr {
    margin: 0 19px 0 30px;
    width: calc(100% - 30px - 19px);
    border: 1px solid #0000001a;
    border-bottom: 0;
  }
`;
