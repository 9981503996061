import { ENVs } from "../../../../utils/constants";

const currentEnvName = () => {
  const envName = Object.keys(ENVs).find(
    (key) => ENVs[key] === window.location.origin
  );
  return envName;
};

export const getBaseUrl = () => {
  if (currentEnvName() === "prod") {
    return "https://salesstream.ai";
  } else if (currentEnvName() === "qa" || currentEnvName() === "dev") {
    return "https://devsite.salesstream.ai";
  } else {
    return "http://localhost:3001";
  }
};

export const getUrlBlogPostFullUrl = (link) => {
  if (currentEnvName() === "qa") {
    return `${getBaseUrl()}/blog/${link}?env=qa`;
  }

  return `${getBaseUrl()}/blog/${link}`;
};

export const sortData = (data, sortField, sortOrder) => {
  if (!sortField) return data;

  return [...data].sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === "boolean" && typeof bValue === "boolean") {
      return sortOrder === "asc"
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    return sortOrder === "asc"
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });
};
