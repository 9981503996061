import React from "react";
import { styled } from "styled-components";
import UndeliverableEmailsTable from "./UndeliverableEmailsTable";

function UndeliverableEmailsWrapper() {
  return (
    <Container>
      <Content>
        <Title>Undeliverable Emails</Title>

        <UndeliverableEmailsTable />
      </Content>
    </Container>
  );
}

export default UndeliverableEmailsWrapper;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  padding: 0 20px 0 0;
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.gray};
`;
