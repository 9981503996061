import React, { useMemo } from "react";

import Plan from "./Plan";
import { useSelectDefaultPlanId } from "../hooks";

import { PlansListContainer } from "./styles";
import { SUBSCRIPTION_PLAN_PERIOD } from "../../../utils/constants";

const Plans = ({ plans, currentPlanId, subscriptionPeriod, onSelect }) => {
  const { selectedPlanId, setSelectedPlanId } = useSelectDefaultPlanId(
    plans,
    currentPlanId
  );
  const isSubscriptionPlansPage =
    window.location.pathname === "/subscription-plans";

  const handleBuyPlanClick = () => {
    onSelect(selectedPlanId);
  };

  const handleSelectPlanClick = (id) => {
    if (id === selectedPlanId) {
      return;
    }
    setSelectedPlanId(id);
  };

  const sortedPlans = useMemo(() => {
    if (!currentPlanId) {
      return plans;
    }

    const currentPlanIdx = plans?.findIndex(
      (plan) => plan.id === currentPlanId
    );

    if (!plans || currentPlanIdx < 0) {
      return plans;
    }

    const currentPlan = plans[currentPlanIdx];
    const otherPlans = plans.slice();
    otherPlans.splice(currentPlanIdx, 1);

    const leftSide = otherPlans.splice(0, Math.floor(otherPlans.length / 2));
    const rightSide = otherPlans;

    return [...leftSide, currentPlan, ...rightSide];
  }, [plans, currentPlanId]);

  return (
    <PlansListContainer $isSubscriptionPlansPage={isSubscriptionPlansPage}>
      {sortedPlans.map((plan, idx) => {
        const isSelected = plan.id === selectedPlanId;
        const price =
          subscriptionPeriod === SUBSCRIPTION_PLAN_PERIOD.YEAR
            ? plan.monthlyPriceAnnualContract
            : plan.monthlyPrice;
        return (
          <Plan
            idx={idx}
            key={plan.id}
            id={plan.id}
            name={plan.planName}
            description={plan.description}
            planItems={plan.planItems}
            price={price}
            isSelected={isSelected}
            isDisabled={plan.id === currentPlanId}
            onSelect={handleSelectPlanClick}
            onBuy={handleBuyPlanClick}
          />
        );
      })}
    </PlansListContainer>
  );
};

export default Plans;
