import React from "react";
import styled from "styled-components";

const ErrorBanner = ({ description, action: { text, moveTo }, onClose }) => {
  return (
    <Container>
      <Icon src="images/hint.svg" alt="hint" />
      <Description>
        {description} Click <LinkToAction onClick={moveTo}>here</LinkToAction>{" "}
        to {text}
      </Description>
      <CloseBtn src="images/close-btn-icon.svg" alt="hint" onClick={onClose} />
    </Container>
  );
};

export default ErrorBanner;

const Container = styled.div`
  height: 40px;
  width: 100%;
  background: rgba(249, 89, 89, 0.57);
  padding: 8px 17px 8px 14px;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  padding: 0 6px;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 1.5em;
  color: #471168;
  flex-grow: 1;
  width: 100%;
`;

const LinkToAction = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
`;

const CloseBtn = styled.button`
  height: 18px;
  width: 20px;
  -webkit-mask-image: url("images/close-btn-icon.svg");
  -webkit-mask-size: cover;
  background-color: rgba(71, 17, 104, 0.5);
  border: none;
  cursor: pointer;
`;
