import React from "react";
import { styled } from "styled-components";
import theme from "../../../theme";
import { CRMS } from "../../../utils/constants";
import { isSpreadsheetType } from "../../../utils/utils";

function CandidateTable({
  columns,
  candidateList,
  setSort,
  sort,
  handleCheckContact,
  checkedContacts,
  crm,
  isCandidates,
  contactsLabel,
}) {
  const isSpreadsheet = isSpreadsheetType(crm);
  const isClientContacts = !isCandidates;

  const handleClickSort = (newSort) => {
    setSort({
      newSort,
      newOrder: sort.sort !== newSort || sort.order === "desc" ? "asc" : "desc",
    });
  };

  const getSortIcon = (sortField) => {
    return (
      <SortIcon
        src={`/images/sort-${
          sort.sort === sortField ? sort.order + "-icon" : "icon"
        }.png`}
        alt="sort"
      />
    );
  };

  const columnsToDisplay = columns?.filter(
    (col) => col !== "Email" && col !== "Email Address" && col !== "Name"
  );

  const renderContactRow = (recipient, isNested = false) => {
    console.log(
      "Rendering row for recipient:",
      recipient,
      "isNested:",
      isNested
    );
    const checked = checkedContacts.includes(recipient.id);
    return (
      <TableRow
        key={recipient.id}
        $isChecked={checked}
        $isInvalid={recipient.isInvalid}
        $isNested={isNested}
        $backgroundColor={recipient.isInvalid ? "#ffe4e480" : ""}
        $borderBottom={`1px solid #E8E8E8`}
      >
        <TableData color={theme.colors.purple}>
          <CheckboxBlock>
            <Checkbox
              id={`select-contact-${recipient.id}`}
              type="checkbox"
              checked={checked}
              value={checked}
              onChange={(event) =>
                handleCheckContact(event.target.checked, recipient.id)
              }
            />
          </CheckboxBlock>
        </TableData>
        {!isSpreadsheet && (
          <TableData>
            {isNested && <NestedIndicator>↳</NestedIndicator>}
            {recipient.id.replace("nested_", "")}
          </TableData>
        )}
        <TableData color={theme.colors.purple}>
          {isNested && <NestedIndicator>↳</NestedIndicator>}
          {recipient.full_name}
        </TableData>
        <TableData>
          {isNested && <NestedIndicator>↳</NestedIndicator>}
          {recipient.email}
        </TableData>
        <TableData>
          {isNested && <NestedIndicator>↳</NestedIndicator>}
          {recipient.emailStatus}
        </TableData>
        {!isSpreadsheet ? (
          <>
            <TableData>
              {isNested && <NestedIndicator>↳</NestedIndicator>}
              {recipient.phoneNumber}
            </TableData>
            {isClientContacts ? (
              <>
                <TableData>
                  {isNested && <NestedIndicator>↳</NestedIndicator>}
                  {recipient.jobTitle}
                </TableData>
                <TableData>
                  {isNested && <NestedIndicator>↳</NestedIndicator>}
                  {recipient.company}
                </TableData>
              </>
            ) : (
              <>
                <TableData>
                  {isNested && <NestedIndicator>↳</NestedIndicator>}
                  {recipient.category}
                </TableData>
                <TableData>
                  {isNested && <NestedIndicator>↳</NestedIndicator>}
                  {recipient.specialty}
                </TableData>
              </>
            )}
          </>
        ) : (
          columnsToDisplay?.map((col) => (
            <TableData key={col}>
              {isNested && <NestedIndicator>↳</NestedIndicator>}
              {recipient.row?.[col]}
            </TableData>
          ))
        )}
      </TableRow>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            $backgroundColor={theme.colors.candidate_table_head_color2}
            $height="30px"
            $borderTop="1px solid #D6D6D6"
          >
            <TableHeadData>
              {candidateList.length > 0 ? (
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="select-all-contacts-checkbox"
                  checked={
                    checkedContacts.length ===
                    candidateList.reduce(
                      (acc, c) => acc + 1 + (c.nestedContacts?.length || 0),
                      0
                    )
                  }
                  value={
                    checkedContacts.length ===
                    candidateList.reduce(
                      (acc, c) => acc + 1 + (c.nestedContacts?.length || 0),
                      0
                    )
                  }
                  onChange={() =>
                    checkedContacts.length !==
                    candidateList.reduce(
                      (acc, c) => acc + 1 + (c.nestedContacts?.length || 0),
                      0
                    )
                      ? handleCheckContact("all")
                      : handleCheckContact("none")
                  }
                />
              ) : (
                <Checkbox type="checkbox" name="selectAll" disabled />
              )}
            </TableHeadData>
            {!isSpreadsheet && (
              <TableHeadData
                $cursorPointer={true}
                onClick={() => handleClickSort("id")}
              >
                <div>
                  ID
                  {getSortIcon("id")}
                </div>
              </TableHeadData>
            )}
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("full_name")}
            >
              <div>
                {`${contactsLabel.substring(0, contactsLabel.length - 1)} Name`}
                {getSortIcon("full_name")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("email")}
            >
              <div>
                Email
                {getSortIcon("email")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("emailStatus")}
            >
              <div>
                Status
                {getSortIcon("emailStatus")}
              </div>
            </TableHeadData>
            {!isSpreadsheet ? (
              <>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("phoneNumber")}
                >
                  <div>
                    Phone
                    {getSortIcon("phoneNumber")}
                  </div>
                </TableHeadData>
                {isClientContacts ? (
                  <>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("jobTitle")}
                    >
                      <div>
                        {crm !== CRMS.BULLHORN ? "Job" : ""} Title
                        {getSortIcon("jobTitle")}
                      </div>
                    </TableHeadData>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("company")}
                    >
                      <div>
                        Company
                        {getSortIcon("company")}
                      </div>
                    </TableHeadData>
                  </>
                ) : (
                  <>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("category")}
                    >
                      <div>
                        Category
                        {getSortIcon("category")}
                      </div>
                    </TableHeadData>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("specialty")}
                    >
                      <div>
                        Specialty
                        {getSortIcon("specialty")}
                      </div>
                    </TableHeadData>
                  </>
                )}
              </>
            ) : (
              columnsToDisplay?.map((col) => (
                <TableHeadData key={col} className="custom-col">
                  {col}
                </TableHeadData>
              ))
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateList.length !== 0 ? (
            candidateList.map((recipient) => (
              <React.Fragment key={recipient.id}>
                {renderContactRow(recipient)}
                {recipient.nestedContacts?.map((nested) =>
                  renderContactRow(nested, true)
                )}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableData colSpan="100">No recipients to show</TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default CandidateTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

const TableRow = styled.tr`
  height: ${({ $height }) => $height || "48px"};
  background-color: ${({ $backgroundColor, $isChecked, theme }) =>
    $backgroundColor ||
    ($isChecked ? theme.colors.candidate_table_head_color : "")};
  border-top: ${({ $borderTop }) => $borderTop || "none"};
  border-bottom: ${({ $borderBottom }) => $borderBottom || "none"};
  padding-left: ${({ $isNested }) => ($isNested ? "20px" : "0")};
`;

const TableHeadData = styled.th`
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? "pointer" : "default")};
  white-space: nowrap;

  > div {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  &.custom-col {
    min-width: 120px;
  }
`;

const TableData = styled.td`
  padding: 0 10px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: ${({ color }) => color || "#000000"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
`;

const SortIcon = styled.img`
  width: 8px;
  height: 12px;
`;

const NestedIndicator = styled.span`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.gray};
`;
