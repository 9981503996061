import React, { useState } from "react";
import { styled } from "styled-components";
import theme from "../../../../theme";
import { exportToCSV } from "../../../../utils/export";

const DAY_TIME_STEP = 24 * 60 * 60 * 1000;

const UsageTable = ({ data, isLoading, onLoad }) => {
  const calcTodayTime = () => {
    const today = new Date();
    return today.setHours(0, -today.getTimezoneOffset(), 0, 0);
  };

  const [todayTime, setTodayTime] = useState(calcTodayTime());
  const [selectedDayTime, setSelectedDayTime] = useState(todayTime);
  const [sort, setSort] = useState({ sort: "createdAt", order: "desc" });

  const withSort = data.length > 1;

  const sortedData = withSort
    ? data.sort((a, b) => {
        if (sort.order === "desc") {
          const tmp = a;
          a = b;
          b = tmp;
        }

        if (sort.sort === "createdAt") {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        }

        if (typeof a[sort.sort] === "number") {
          return a[sort.sort] - b[sort.sort];
        }

        return String(a[sort.sort] || "").localeCompare(
          String(b[sort.sort] || "")
        );
      })
    : data;

  const handleClickSort = (sortField) => {
    setSort((curSort) => {
      return {
        sort: sortField,
        order: curSort.order === "asc" ? "desc" : "asc",
      };
    });
  };

  const prepareSelectedDate = () => {
    return new Date(selectedDayTime).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    });
  };

  const prepareDate = (usage) => {
    return new Date(usage.createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  const handleLoad = (dayTime) => {
    setTodayTime(calcTodayTime());
    setSelectedDayTime(dayTime);
    onLoad(new Date(dayTime).toISOString());
  };

  const handleClickRefresh = () => {
    handleLoad(selectedDayTime);
  };

  const handleClickNext = () => {
    handleLoad(selectedDayTime + DAY_TIME_STEP);
  };

  const handleClickPrev = () => {
    handleLoad(selectedDayTime - DAY_TIME_STEP);
  };

  const exportToFile = () => {
    const headers = [
      { name: "promptType", label: "Type" },
      { name: "aiCreditsUsed", label: "# of Credits" },
    ];
    exportToCSV(headers, data, `usage-credits-${prepareSelectedDate()}.csv`);
  };

  return (
    <TableLayout>
      <TableTitleBlock>
        <TableTitle>
          Daily Stats - {prepareSelectedDate()}
          <DayButtonBlock>
            <DayButton
              title="Previous Day"
              disabled={isLoading}
              onClick={handleClickPrev}
            >
              &lt;&nbsp;Prev
            </DayButton>
            <DayButton
              title="Next Day"
              disabled={isLoading || todayTime === selectedDayTime}
              onClick={handleClickNext}
            >
              Next&nbsp;&gt;
            </DayButton>
          </DayButtonBlock>
        </TableTitle>
        <ButtonBlock>
          <ActionButton onClick={exportToFile} disabled={isLoading}>
            Export
          </ActionButton>
          <ActionButton onClick={handleClickRefresh} disabled={isLoading}>
            <RefreshIcon src="/images/refresh-icon.png" />
            Refresh
          </ActionButton>
        </ButtonBlock>
      </TableTitleBlock>
      <Table>
        <TableHead>
          <TableRow $bgColor="#f3f2f2">
            <TableHeadData
              $padding="15px 5px 15px 15px"
              $maxWidth="200px"
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort("createdAt"),
                  }
                : {})}
            >
              <TableHeadDataSort>
                Date
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === "createdAt" ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
            <TableHeadData>
              <TableHeadDataSort>Type</TableHeadDataSort>
            </TableHeadData>
            <TableHeadData>
              <TableHeadDataSort># of Credits</TableHeadDataSort>
            </TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          {!sortedData.length ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No data</EmptyTableData>
            </TableRow>
          ) : (
            sortedData.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  $borderBottom={`1px solid ${theme.colors.divider_color}`}
                >
                  <TableData $maxWidth="200px" $padding="15px 5px 15px 15px">
                    {prepareDate(item)}
                  </TableData>
                  <TableData>{item.promptType}</TableData>
                  <TableData>
                    {item.aiCreditsUsed
                      ? Math.ceil(item.aiCreditsUsed / 1000)
                      : ""}
                  </TableData>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableLayout>
  );
};

export default UsageTable;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    font-weight: 800;
    color: #494949;
  }
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 15px 5px"};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 90px;
  z-index: 11;
`;

const TableRow = styled.tr`
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 15px 5px"};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableLayout = styled.div`
  height: fit-content;
  border: 1px solid #e2e2e2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  background-color: #fbfbfb;
  max-width: 1440px;
  width: 100%;
  flex-grow: 1;
  min-height: 180px;
  overflow-y: auto;
`;

const TableTitleBlock = styled.div`
  position: sticky;
  top: 0px;
  z-index: 11;
  display: flex;
  align-items: center;
  padding: 25px 10px 25px 15px;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
`;

const TableTitle = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #5a5a5a;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const ActionButton = styled.button`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 700;
  width: 118px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.gray};
  box-shadow: none !important;
  outline: none !important;
  &:hover {
    background-color: #efefef;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;

const RefreshIcon = styled.img`
  width: 19.63px;
  height: 21.67px;
`;

const DayButtonBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 10px;
  padding: 6px 0 0;
`;

const DayButton = styled.button`
  padding: 0;
  color: ${theme.colors.purple};
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  border: none !important;
  outline: none !important;
  text-transform: uppercase;
  cursor: pointer;
  &:not(:hover) {
    background: none;
    box-shadow: none;
  }
  &:hover {
    background: #efefef;
    box-shadow: 0 0 0 3px #efefef;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;
