import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SuggestionPopup from "../components/SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { CRM_LABELS, CRMS } from "../utils/constants";
import { isSpreadsheetType } from "../utils/utils";

const Alert = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const goToCrmPrm = searchParams.get("goToCrm");
  const goToCrm = Object.values(CRMS).includes(goToCrmPrm) ? goToCrmPrm : null;

  const userInfo = getUserFromLocalStorage();

  const closeIframe = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  const openSampleSheet = () => {
    sendMessageToExtension({
      message: "open-sample-sheet",
    });
  };

  const buildDesc = () => {
    if (goToCrm) {
      return `Please navigate to a browser tab with ${CRM_LABELS[goToCrm]}${
        !isSpreadsheetType(goToCrm)
          ? " CRM"
          : goToCrm === CRMS.GOOGLE_SHEETS
          ? ""
          : " spreadsheet"
      } to add contacts to a campaign`;
    }

    const crmNotFoundMsg =
      "Please navigate to a browser tab with your CRM or spreadsheet to start a campaign";

    if (userInfo?.hasCreatedCampaign) {
      return crmNotFoundMsg;
    }

    return (
      <>
        {`${crmNotFoundMsg}. You can see a sample sheet`}{" "}
        <Link onClick={openSampleSheet}>here</Link>
      </>
    );
  };

  return (
    <SuggestionPopup
      imageSrc="/logo192.png"
      title="We can't find your contacts!"
      description={buildDesc()}
      confirmBtnText="Got it!"
      onConfirm={closeIframe}
      removeCancel
      noOverlay
    />
  );
};

export default Alert;

const Link = styled.a`
  font-size: inherit;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.15s ease-out;
  color: #0091ae;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #007a8c;
    text-decoration: underline;
  }
`;
