import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import ConnectBtn from "../../../ConnectBtn";
import CustomSelect from "../../../CustomSelect";
import { getCompanyFilters } from "../../../../services/company";
import BusinessHours from "../Scheduling/BusinessHours";
import Toggle from "../Scheduling/Toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MAX_EMAIL_PER_MINUTE = 30;
const CREDITS_PER_MONTH = 0;
const CREDITS_REMAINING = 0;
const BILLING_DAY = 1;

const FIELDS = [
  { label: "Name", name: "name" },
  { label: "Admin", name: "adminId", type: "select" },
  { label: "Allow Oauth", name: "allowSimpleConnect", type: "boolean" },
  { label: "Allow Unsubscribe", name: "allowUnsubscribe", type: "boolean" },
  {
    label: "Allow Prompt Playground",
    name: "allowPromptPlayground",
    type: "boolean",
  },
  { label: "Show Reach Branding", name: "showBranding", type: "boolean" },
  {
    label: "Manual Greeting & Signature",
    name: "manualGreetingAndSignature",
    type: "boolean",
  },
  {
    label: "Use replacement signature",
    name: "useReplacementSignature",
    type: "boolean",
  },
  {
    label: "Credits per Month",
    name: "creditsPerMonth",
    type: "number",
    defaultValue: CREDITS_PER_MONTH,
    props: { min: 0 },
    isDisabled: (c) => !!c.subscriptionPlanId,
  },
  {
    label: "Credits Remaining",
    name: "creditsRemaining",
    type: "number",
    defaultValue: CREDITS_REMAINING,
    props: { min: 0 },
    isDisabled: (c) => !!c.unpaidSubscription,
  },
  {
    label: "Billing Day",
    name: "billingDay",
    type: "number",
    defaultValue: BILLING_DAY,
    props: { min: 1, max: 31 },
    isDisabled: (c) => !!c.subscriptionPlanId,
  },
  {
    label: "Max Emails Per Minute",
    name: "maxEmailsPerMinute",
    type: "number",
    defaultValue: MAX_EMAIL_PER_MINUTE,
    props: { min: 1 },
  },
  { label: "Business Hours", name: "businessHours", type: "businessHours" },
  { label: "Default Model", name: "defaultModelId", type: "select" },
  { label: "Regular Model", name: "regularModelId", type: "select" },
  { label: "Premium Model", name: "premiumModelId", type: "select" },
  {
    label: "Doctor Categories",
    name: "doctorCategories",
    optional: true,
    placeholder: "Enter separated by commas",
  },
  { label: "Doctor Degree Field", name: "doctorDegreeField", optional: true },
  {
    label: "Doctor Degree Values",
    name: "doctorDegreeValues",
    optional: true,
    placeholder: "Enter separated by commas",
  },
];

const DEFAULT_VALUE = "[none]";
const DEFAULT_OPTION = {
  value: DEFAULT_VALUE,
  label: <span style={{ color: "#757678" }}>None</span>,
};

const CompanyFormPopup = ({ data, onClose, onSave }) => {
  const isEditMode = !!data.id;

  const prepareData = (d) => {
    if (!d.hasOwnProperty("id")) {
      return {
        isNew: true,
        id: "",
        name: "",
        adminId: DEFAULT_VALUE,
        allowUnsubscribe: false,
        allowSimpleConnect: true,
        allowPromptPlayground: false,
        showBranding: false,
        businessHours: {},
        maxEmailsPerMinute: MAX_EMAIL_PER_MINUTE,
        creditsPerMonth: CREDITS_PER_MONTH,
        creditsRemaining: CREDITS_REMAINING,
        billingDay: BILLING_DAY,
        doctorCategories: [],
        doctorDegreeField: "",
        doctorDegreeValues: [],
        manualGreetingAndSignature: false,
        useReplacementSignature: true,
      };
    }
    if (!d.adminId) {
      return { ...d, adminId: DEFAULT_VALUE };
    }
    return d;
  };

  const adaptDataJson = (values) => {
    return {
      ...values,
      doctorCategories: values.doctorCategories.join(", "),
      doctorDegreeValues: values.doctorDegreeValues.join(", "),
    };
  };

  const dataJson = JSON.stringify(prepareData(data));
  const [values, setValues] = useState(adaptDataJson(JSON.parse(dataJson)));
  const [errorMessages, setErrorMessages] = useState({});
  const [adminOptions, setAdminOptions] = useState(
    isEditMode ? null : [DEFAULT_OPTION]
  );
  const [modelsOptions, setModelsOptions] = useState(null);

  useEffect(() => {
    const filter = ["models"];
    if (isEditMode) {
      filter.push("admins");
    }
    const loadFilters = async () => {
      const res = await getCompanyFilters({
        companyId: data.id,
        filter,
      });
      if (isEditMode) {
        const users = (res.result?.users || []).map((c) => ({
          value: c.id,
          label: c.name,
        }));
        setAdminOptions(users.length ? users : [DEFAULT_OPTION]);
      }
      setModelsOptions(
        (res.result?.models || [])
          .sort((a, b) =>
            `${a.modelMode}--${a.modelId}`.localeCompare(
              `${b.modelMode}--${b.modelId}`
            )
          )
          .map((m) => ({
            value: m.objectId,
            label: (
              <ModelOption
                $modelMode={m.modelMode}
                $withCreditTokenMultiplier={!!m.creditTokenMultiplier}
                title="Model ID"
              >
                <span title="Mode">{m.modelMode}</span>
                {m.modelId}
                {!!m.creditTokenMultiplier && (
                  <sup title="Credit Token Multiplier">
                    x{m.creditTokenMultiplier}
                  </sup>
                )}
              </ModelOption>
            ),
          }))
      );
    };
    loadFilters();
  }, [isEditMode, data.id]);

  const handleClose = (event) => {
    event?.preventDefault();
    onClose();
  };

  const prepareInputValue = (event, field) => {
    let value = event.target.value;
    if (field.type === "number") {
      if (/^[-]?[0-9]+$/.test(value)) {
        value = +value;
        if (value === -0) {
          value = 0;
        }
        if (typeof field.props?.max === "number" && value > field.props.max) {
          value = field.props.max;
        } else if (
          typeof field.props?.min === "number" &&
          value < field.props.min
        ) {
          value = field.props.min;
        }
      } else {
        value =
          typeof field.defaultValue === "number"
            ? field.defaultValue
            : undefined;
      }
    } else {
      value = value.trim();
    }
    return value;
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = {};

    FIELDS.forEach((f) => {
      if (f.optional) {
        return;
      }

      if (
        (f.type === "number" && typeof values[f.name] !== "number") ||
        (!f.type && !values[f.name])
      ) {
        errors[f.name] = `Missed ${[f.label]}`;
      } else if (f.type === "businessHours") {
        const businessHours = values[f.name];
        if (Object.keys(businessHours).length) {
          const missed = [];
          if (!businessHours.from) {
            missed.push('"From"');
          }
          if (!businessHours.to) {
            missed.push('"To"');
          }
          if (!businessHours.tz) {
            missed.push('"Time Zone"');
          }
          if (missed.length) {
            errors[f.name] = `Missed ${missed.join(", ")}`;
          } else if (
            businessHours.from &&
            businessHours.to &&
            JSON.stringify(businessHours.from) ===
              JSON.stringify(businessHours.to)
          ) {
            errors[f.name] = '"From" and "To" should be different';
          }
        }
      }
    });

    if (Object.keys(errors).length) {
      setErrorMessages(errors);
    } else {
      onSave(adaptFieldValues(values));
    }
  };

  const renderFieldCtrl = (field) => {
    const setValue = (value) => {
      setValues({ ...values, [field.name]: value });
      setErrorMessages((m) => ({
        ...m,
        [field.name]: "",
      }));
    };

    if (field.type === "businessHours") {
      return (
        <BusinessHours
          businessHours={values[field.name]}
          setBusinessHours={setValue}
          allowEmpty
        />
      );
    }

    if (field.type === "select" && field.name === "adminId") {
      return (
        <AdminSelect
          $disabled={!adminOptions || !!values.unpaidSubscription}
          $noSelected={values[field.name] === DEFAULT_VALUE}
        >
          <CustomSelect
            options={adminOptions || []}
            title={
              !isEditMode ? (
                ""
              ) : adminOptions ? (
                `Select ${field.label}...`
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
              )
            }
            width="100%"
            selected={values[field.name]}
            onSelect={setValue}
          />
        </AdminSelect>
      );
    }

    if (field.type === "select" && field.name.endsWith("ModelId")) {
      return (
        <AdminSelect
          $disabled={!modelsOptions?.length}
          $noSelected={!modelsOptions?.length || !values[field.name]}
        >
          <CustomSelect
            options={modelsOptions || []}
            title={
              !modelsOptions ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                `Select Model...`
              )
            }
            width="100%"
            selected={
              !modelsOptions?.length ? null : values[field.name] || null
            }
            onSelect={(val) => setValue(val || "")}
            isClearable={true}
          />
        </AdminSelect>
      );
    }

    if (field.type === "boolean") {
      return (
        <Toggle
          id={field.name}
          label={field.label}
          value={values[field.name]}
          onChange={setValue}
          width="90%"
        />
      );
    }

    return (
      <Input
        id={field.name}
        placeholder={field.placeholder || `Enter ${field.label}...`}
        value={values[field.name]}
        onBlur={(e) => {
          setValue(prepareInputValue(e, field));
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        autoComplete="off"
        type={field.type}
        disabled={!!field.isDisabled && field.isDisabled(values)}
        {...field.props}
      />
    );
  };

  const adaptFieldValues = (values) => {
    const adaptedFieldValues = {
      ...values,
      doctorCategories: splitAndTrimValue(values.doctorCategories),
      doctorDegreeValues: splitAndTrimValue(values.doctorDegreeValues),
    };

    if (values.adminId === DEFAULT_VALUE) {
      adaptedFieldValues.adminId = "";
    }

    return adaptedFieldValues;
  };

  const splitAndTrimValue = (value) => {
    return value
      ? value
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean)
      : [];
  };

  return (
    <InviteContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit}>
          {FIELDS.map((f) => {
            const field = {
              ...f,
              ...(isEditMode && f.name === "creditsRemaining"
                ? { props: { ...f.props, min: undefined } }
                : {}),
            };
            return (
              <Inputs key={field.name}>
                <InputBlock>
                  {field.type !== "boolean" && (
                    <Label htmlFor={field.name}>{field.label}</Label>
                  )}
                  <Relative>
                    {renderFieldCtrl(field)}
                    {!!errorMessages[field.name] && (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    )}
                  </Relative>
                  {!!errorMessages[field.name] && (
                    <ErrorMessage>{errorMessages[field.name]}</ErrorMessage>
                  )}
                </InputBlock>
              </Inputs>
            );
          })}

          <Actions>
            <ConnectBtn
              type="submit"
              label={`${isEditMode ? "Update" : "Create"} Company`}
              disabled={dataJson === JSON.stringify(values)}
            />
          </Actions>
        </Form>
      </Content>
    </InviteContainer>
  );
};

export default CompanyFormPopup;

const InviteContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  max-height: 70vh;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(70vh - 80px);
  overflow: auto;
`;

const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .custom-business-hours-cont {
    margin-bottom: -8px;
    > div > label,
    > div > div > label {
      font-size: 12px;
      height: 20px;
    }
    .multi-select {
      .dropdown-heading-value {
        span.gray {
          display: none !important;
        }
      }
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  box-shadow: 0 0 10px #dfdfdf;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const AdminSelect = styled.div`
  width: 90%;
  margin-bottom: 5px;
  > div:first-child {
    ${({ $disabled }) =>
      $disabled ? "opacity: 0.65; pointer-events: none;" : ""}
    margin-bottom: 0;
    height: 40px;
    > div:first-child {
      height: 40px;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
        sup {
          display: none;
        }
      }
    }
  }
`;

const ModelOption = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  grid-template-columns: ${({ $withCreditTokenMultiplier }) =>
    `45px 1fr${$withCreditTokenMultiplier ? " 20px" : ""}`};
  span {
    width: fit-content;
    font-weight: bolder;
    ${({ $modelMode }) => ($modelMode === "Azure" ? "color: #0078d4;" : "")}
  }
  sup {
    font-size: smaller;
    color: #757678;
    text-align: right;
  }
`;
