import React, { useState } from "react";
import styled from "styled-components";
import Preview from "./Preview";
import Thumbnails from "./Thumbnails";

const previewListData = [
  {
    id: 1,
    preview: "images/hupspot-app/1.png",
    carousel: "images/hupspot-app/1.png",
    type: "image",
  },
  {
    id: 2,
    preview: "images/hupspot-app/2.png",
    carousel: "images/hupspot-app/2.png",
    type: "image",
  },
  {
    id: 3,
    preview: "images/hupspot-app/4.png",
    carousel: "images/hupspot-app/4.png",
    type: "image",
  },
  {
    id: 4,
    preview: "images/hupspot-app/5.png",
    carousel: "images/hupspot-app/5.png",
    type: "image",
  },

  {
    id: 5,
    preview: "video/intro_video3.webm",
    altPreview: "video/intro_video3.mp4",
    carousel: "images/hupspot-app/video-thumbnail-3.png",
    type: "video",
  },
];

function Carousel() {
  const [selectedPreview, setSelectedPreview] = useState(previewListData[0]);

  const movePreviewItem = (direction) => {
    const selectedId = selectedPreview.id;
    let realId;

    if (direction === "next") {
      realId = selectedId + 1;

      if (realId > previewListData.length) {
        realId = 1;
      }
    } else {
      realId = selectedId - 1;

      if (realId === 0) {
        realId = previewListData.length;
      }
    }

    const foundedPreview = previewListData.find(
      (previewItem) => realId === previewItem.id
    );

    setSelectedPreview(foundedPreview);
  };

  return (
    <Container>
      <Preview
        previewItem={selectedPreview}
        movePreviewItem={movePreviewItem}
      />
      <Thumbnails
        listData={previewListData}
        previewItem={selectedPreview}
        setPreview={setSelectedPreview}
      />
    </Container>
  );
}

export default Carousel;

const Container = styled.div`
  padding: 0 20px 0 20px;
`;
