import styled from "styled-components";

export const ConnectedProviderBlock = styled.div`
  min-height: 88px;
  background-color: #f3f3f3;
  margin-right: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 34px;
  border-radius: 5px;
  column-gap: 20px;

  & + & {
    margin-top: 20px;
  }
`;

export const OutlineBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  padding: 0 30px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
`;

export const BtnGroup = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 22px;
  row-gap: 15px;
  justify-content: flex-end;
`;
