import React from "react";

import { getUrlBlogPostFullUrl } from "../utils";

import { TableRow, TableData, BlogActions, ActionButton } from "./styles";

const BlogPostRow = ({
  post,
  onEdit,
  onRemove,
  onToggleActive,
  onOpenLinkPopup,
}) => {
  const { id, title, isActive, link } = post;

  return (
    <TableRow key={id}>
      <TableData>{title}</TableData>
      <TableData>{isActive ? "Yes" : "No"}</TableData>
      <TableData>
        <BlogActions>
          <ActionButton
            className="button button-primary"
            onClick={(event) => onEdit(event, id)}
          >
            Edit
          </ActionButton>
          <ActionButton
            className="button button-primary"
            onClick={() => onRemove(id)}
          >
            Delete
          </ActionButton>
          <ActionButton
            className="button button-primary"
            onClick={() => onToggleActive(id, !isActive)}
          >
            {isActive ? "Deactivate" : "Activate"}
          </ActionButton>
          {isActive && (
            <ActionButton
              className="button button-primary"
              onClick={() => onOpenLinkPopup(getUrlBlogPostFullUrl(link))}
            >
              View Link
            </ActionButton>
          )}
        </BlogActions>
      </TableData>
    </TableRow>
  );
};

export default BlogPostRow;
