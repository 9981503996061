import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  overflow: hidden;
  height: inherit;
`;

export const Container = styled.div`
  overflow: auto;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: inherit;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ $isBillingDetailsUpdate }) =>
      $isBillingDetailsUpdate ? "125px" : "388px"};
    z-index: -1;
    background-image: url(/images/subscription-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000; /* it's hack to avoid the color on top in the corners from the parent 'background-color'. */

    @media (max-width: 812px) {
      height: ${({ $isBillingDetailsUpdate }) =>
        $isBillingDetailsUpdate ? "125px" : "450px"};
    }
  }
`;

export const Logo = styled.img`
  width: 162px;
  height: 58px;
  margin: 58px 0 0 58px;
`;

export const BillingDetails = styled.div`
  padding: 0 0 20px 0;
`;

export const Details = styled.div`
  display: flex;
  margin-top: 21px;
  padding: 0 35px 0 107px;

  @media (max-width: 812px) {
    flex-direction: column;
  }
`;

export const DetailsHeader = styled.p`
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  line-height: 27.3px;
  letter-spacing: -0.02em;
  max-width: 290px;
  padding-right: 10px;

  @media (max-width: 812px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const DetailsDescription = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  position: relative;
  max-width: 380px;
  padding: 0 60px 0 17px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 54px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 2px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 54px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 2px;
  }

  @media (max-width: 812px) {
    &::before {
      height: 2px;
      width: 100%;
    }

    &::after {
      height: 2px;
      width: 100%;
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    }

    margin-bottom: 10px;
    max-width: 100%;
    padding: 10px 0;
  }
`;

export const SwitchContainer = styled.div`
  position: relative;
  padding-left: 28px;

  @media (max-width: 812px) {
    display: flex;
    padding: 10px 0 0 0;
  }
`;

export const PlansContainer = styled.div`
  position: relative;
  margin-top: 83px;
  padding-left: 128px;
  padding-bottom: 56px;

  @media (max-width: 1160px) {
    padding-left: 0;
  }
`;
