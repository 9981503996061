/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { displayDate, sortRows } from "../../../../utils/utils";
import theme from "../../../../theme";

const PlacementResultsPopup = (props) => {
  const [data, setData] = useState(props.data);
  const [sort, setSort] = useState({ sort: null, order: null });

  const columns = [
    { name: "emailAddress", label: "Email Address", sortable: true },
    {
      name: "sentOn",
      label: "Sent On",
      sortable: true,
      render: (row) => displayDate(row.sentOn, true),
    },
    { name: "status", label: "Status", sortable: true },
    { name: "result", label: "Result", sortable: true },
  ];

  const sortData = (rows, sortParams) => {
    const { sort: sortField, order: sortOrder } = sortParams || sort;

    const columnRender = columns.find((c) => c.name === sortField)?.render;

    setData(sortRows(rows, sortField, sortOrder, columnRender));
  };

  useEffect(() => {
    sortData(props.data);
  }, [props.data]);

  const handleClickSort = async (sortField) => {
    const newSort =
      sortField !== sort.sort
        ? { sort: sortField, order: "asc" }
        : { ...sort, order: sort.order !== "asc" ? "asc" : "desc" };
    setSort(newSort);
    sortData(data, newSort);
  };

  const dataLength = data.length;
  const withSort = dataLength > 1;

  const handleClose = (event) => {
    event?.preventDefault();
    props.onClose();
  };

  return (
    <Container>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>
        <OverflowContent>
          <Table>
            <TableHead>
              <TableRow $bgColor="#f3f2f2">
                {columns.map(
                  ({ name, label, sortable = false, skipped = false }, index) =>
                    !skipped && (
                      <TableHeadData
                        key={index}
                        {...(withSort && sortable
                          ? {
                              $cursorPointer: true,
                              onClick: () => handleClickSort(name),
                            }
                          : {})}
                      >
                        <TableHeadDataSort>
                          {label}
                          {withSort && sortable && (
                            <SortIcon
                              src={`/images/sort-${
                                sort.sort === name ? `${sort.order}-` : ""
                              }icon.png`}
                              alt="sort"
                            />
                          )}
                        </TableHeadDataSort>
                      </TableHeadData>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {data.map((item, rowIdx) => (
                  <TableRow
                    key={`${rowIdx}-r`}
                    $borderBottom={`1px solid ${theme.colors.divider_color}`}
                  >
                    {columns.map(
                      ({ name, render, skipped = false }, colIdx) =>
                        !skipped && (
                          <TableData key={`${rowIdx}-${colIdx}`}>
                            {render ? render(item) : item[name]}
                          </TableData>
                        )
                    )}
                  </TableRow>
                ))}
              </React.Fragment>
            </TableBody>
          </Table>
        </OverflowContent>
      </Content>
    </Container>
  );
};

export default PlacementResultsPopup;

const Container = styled.div`
  position: fixed;
  background: #00000050;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  margin: calc(5vh + 17px + 25px) auto;
  height: calc(90vh - (17px + 25px) * 2);
  width: calc(90vw - (34px + 25px) * 2);
`;

const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -34px;
  top: -17px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const OverflowContent = styled.div`
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 0 0 4px #fff;
  background: #fff;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;
